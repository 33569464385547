import React, { Fragment, useState, useEffect } from 'react';
import { Col, Card, CardHeader, CardBody, Row, Modal, ModalHeader, ModalBody, Label, FormGroup, Form, Dropdown, DropdownToggle, DropdownItem, DropdownMenu } from 'reactstrap';
import { useForm } from 'react-hook-form';
import DataTable from 'react-data-table-component';
import { H3, Btn, Breadcrumbs, H4 } from '../../../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';
import SweetAlert from 'sweetalert2';
import { toast } from 'react-toastify';
import { getIndustryUser, getUserSubscription } from '../../../CommonURL';
import { getDataWithToken, postDataWithToken } from '../../../Utils/restUtils';
import { Add, Delete, Edit } from '../../../Constant';
import { CompanyColumns } from '../../../Data/MockTable/CompanyData';
import SearchList from '../../../CommonElements/Search';
import { SubscriptionColumns } from '../../../Data/MockTable/TableHeaderList';
import Transparentspinner from '../../Common/loader/Transparentspinner';

const SubscriptionTable = () => {
    const style2 = { width: 60, fontSize: 13, padding: 3, marginRight: '10px' };
    const [companyList, setCompanyList] = useState([]);
    const [companyData, setCompanyData] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();
    const toggleStyle = { padding: '10px 20px', opacity: '0.8', fontSize: '14px' }


    useEffect(() => {
        subscriptionData(1); // do not put await here
    }, [searchKeyword]);

    const SubscriptionColumns = [
        {
            name: <H4>Sl</H4>,
            selector: row => row.Si,
            sortable: true,
            width: '70px',
        },
        {
            name: <i className="fa fa-gears" style={{ fontSize: '15px' }}></i>,
            selector: row => row.action,
            cell: row => <ActionCell row={row} />,
            center: true,
            width: '55px',
        },
        {
            name: <H4>Name</H4>,
            selector: row => row.Name,
            sortable: true,
            width: '190px',
            wrap: true
        },
        {
            name: <H4>Package Name</H4>,
            selector: row => row.PackageName,
            sortable: true,
            width: '130px',
            wrap: true
        },
        {
            name: <H4>Unit Price</H4>,
            selector: row => <span className='billerq_color fw-bolder'>₹ {row.Price}</span>,
            sortable: true,
            width: '110px',
        },
        {
            name: <H4>Industry</H4>,
            selector: row => row.Industry,
            sortable: true,
            width: '130px',
        },
        {
            name: <H4>Start date</H4>,
            selector: row => row.Start_date,
            sortable: true,
            width: '150px',
            wrap: true,
        },
        {
            name: <H4>End date</H4>,
            selector: row => row.End_date,
            sortable: true,
            width: '150px',
            wrap: true,
        },
        {
            name: <H4>Status</H4>,
            selector: row => <span className={`badge badge-${row.Status}`}>{row.Status}</span>,
            sortable: true,
            width: '90px',

        },
        // {
        //   name: <H4>Action</H4>,
        //   selector: row => row.action,
        //   sortable: true,
        // }
    ];

    const handlePageChange = page => {
        subscriptionData(page);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        subscriptionData(page, newPerPage);
        setPerPage(newPerPage);
    };

    const redirectToEditForm = (data) => {
        const editFormURL = `${process.env.PUBLIC_URL}/billing/subscription-edit?id=${data}`;
        navigate(editFormURL);
    };
    const ActionCell = ({ row }) => {
        const [dropdownOpen, setDropdownOpen] = React.useState(false);
        const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

        return (
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle color="#00000" style={{ fontSize: '14px', zIndex: 1000 }}>
                    <i className="fa fa-ellipsis-v"></i>
                </DropdownToggle>
                <DropdownMenu container="body">
                    <DropdownItem style={toggleStyle} onClick={() => redirectToEditForm(row.Id)}>{Edit}</DropdownItem>
                    <DropdownItem style={{ padding: '10px 20px', color: 'red', opacity: '0.8', fontSize: '14px', margin: '0' }} onClick={() => deleteCompanyList()}>{Delete}</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        );
    };

    const deleteCompanyList = (userId) => {
        SweetAlert.fire({
            title: 'Are you sure?',
            text: 'Once deleted, you will not be able to recover this imaginary file!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'cancel',
            reverseButtons: true
        }).then(async (result) => {
            if (result.value) {
                const deleteRes = { package_id: userId }
                const packageRemove = await postDataWithToken(deletePackage, deleteRes);
                if (packageRemove.data.message === 'success') {
                    SweetAlert.fire(
                        'Deleted!',
                        'Your file has been deleted.',
                        'success'
                    );
                    getPackagesData();
                } else {
                    navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
                }
            } else {

            }
        });
    };

    const filteredItems = companyData.filter(
        (item) => item.first_name && item.first_name.toLowerCase().includes(searchKeyword.toLowerCase()) ||
            item.last_name && item.last_name.toLowerCase().includes(searchKeyword.toLowerCase()) ||
            item.industry_name && item.industry_name.toLowerCase().includes(searchKeyword.toLowerCase())
    );

    const updateSearchKeyword = (keyword) => {
        setSearchKeyword(keyword);
    };

    const subscriptionData = async (page, size = perPage) => {
        setLoading(true);
        let response = await getDataWithToken(getUserSubscription + `?page=${page}&page_length=${size}&search=&industry_id=&user_id=`);
        setResetPaginationToggle(!resetPaginationToggle);
        setCompanyData(response.data.data)
        if (searchKeyword === '') {
            setCompanyList(response.data.data.map((e, index) => {
                return {
                    Si: index + 1,
                    Name: <span>{e.user_name}</span>,
                    PackageName: e.package_name,
                    Price: e.price,
                    Industry: e.industry_name,
                    Start_date: e.start_date,
                    End_date: e.end_date,
                    Created_by: e.created_user_name,
                    Status: e.status,
                    Id: e.id,
                    // action:
                    //     <div>
                    //         <span>
                    //             <Btn attrBtn={{ style: style2, color: 'primary btn-xs', type: 'button', onClick: () => redirectToEditForm(e) }}>Edit </Btn>
                    //         </span>
                    //         <span>
                    //             <Btn attrBtn={{ style: style2, className: 'me-2', color: 'danger btn-xs', type: 'button', onClick: () => { deleteCompanyList(e.id) } }}>Delete</Btn>
                    //         </span>
                    //     </div >
                }
            }))
        } else {
            setCompanyList(filteredItems.map((e, index) => {
                return {
                    Si: index + 1,
                    Name: <span>{e.user_name}</span>,
                    PackageName: <div>{e.package_name}</div>,
                    InvoiceNo: e.email,
                    Industry: e.industry_name,
                    Start_date: e.start_date,
                    End_date: e.end_date,
                    Created_by: e.created_user_name,
                    Status: e.status,
                    // action:
                    //     <div>
                    //         <span>
                    //             <Btn attrBtn={{ style: style2, color: 'primary btn-xs', type: 'button', onClick: () => redirectToEditForm(e) }}>Edit </Btn>
                    //         </span>
                    //         <span>
                    //             <Btn attrBtn={{ style: style2, className: 'me-2', color: 'danger btn-xs', type: 'button', onClick: () => { deleteCompanyList(e.id) } }}>Delete</Btn>
                    //         </span>
                    //     </div >
                }
            }))
        }
        setTotalRows(response.data.total);
        setLoading(false);
    };

    return (
        <Fragment>
            {loading && <Transparentspinner />}
            <Breadcrumbs mainTitle='Subscription' parent="Configuration" title="Subscription" />
            <Card>
                <CardHeader>
                    <H3>Subscription List</H3>
                </CardHeader>
                <div style={{ paddingLeft: '22px', marginRight: '22px', marginBottom: '-10px' }}>
                    <div style={{ float: 'left' }}>
                        <SearchList updateSearchKeyword={updateSearchKeyword} />
                    </div>
                    <div style={{ float: 'right' }}>
                        {/* <Link to={`${process.env.PUBLIC_URL}/billing/subscription-add`} className="btn btn-primary">{Add}</Link>       */}
                      </div>
                    <div style={{ clear: 'both' }}></div>
                </div>
                <CardBody>
                    <div className="order-history table-responsive">
                        <DataTable
                            columns={SubscriptionColumns}
                            data={companyList}
                            pagination
                            paginationServer
                            defaultSortAsc
                            paginationTotalRows={totalRows}
                            paginationDefaultPage={currentPage}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                        />
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    );
};

export default SubscriptionTable;