import React, { Fragment, useState, useEffect } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row } from 'reactstrap';
import { Btn, H3, Breadcrumbs } from "../../../AbstractElements";
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { addAddons, getIndustryData } from "../../../CommonURL";
import { getDataWithToken, postDataWithToken } from "../../../Utils/restUtils";
import { DiscriptionText, Name, NameError, SubmitForm, AddAddons, Price, MRP, Industry, PriceError, MrpError, IndustryError } from '../../../Constant';
import SpinnerLoader from "../../../Layout/SpinnerLoader";
import Typeahead from "../../../CommonElements/TypeAhead";
import Transparentspinner from '../../Common/loader/Transparentspinner';

const UserAddressForm = () => {
    const { register, handleSubmit, control, formState: { errors } } = useForm();
    const [industry, setIndustry] = useState([]);
    const [industryOption, setIndustryOption] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        industryData();
    }, []);

    useEffect(() => {
        setIndustryOption(industry.map((item) => ({
            value: item.id,
            label: item.name,
        })));
    }, [industry]);

    const industryData = async () => {
        const data = await getDataWithToken(getIndustryData);
        setIndustry(data.data);
    };

    const onSubmit = async (data) => {
        setLoading(true);
        const postRequest = { name: data.name, industry_type_id: data.industry.value, price: data.price, mrp: data.mrp, description: data.message }
        const addAddonsResponse = await postDataWithToken(addAddons, postRequest);
        if (addAddonsResponse.data.message === "success") {
            navigate(`${process.env.PUBLIC_URL}/master-records/addons`);
        } else {
            setLoading(false);
            errors.showMessages();
        }
        setLoading(false);
    };
    return (
        <Fragment>
            {loading && <Transparentspinner />}

            <Breadcrumbs mainTitle='Add User Address' parent="Administration" title="User Address" />
            <Card>
                <CardHeader className="pb-0">
                    <H3>{AddAddons}</H3>
                </CardHeader>
                <CardBody>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Row className="g-3">
                            <Col md='6'>
                                <Label className="form-label" for="validationCustom01">{Name}</Label>
                                <input className="form-control" id="validationCustom01" type="text" {...register('name', { required: true })} />
                                <span className="text-danger">{errors.name && NameError}</span>
                            </Col>
                            <Col md="6">
                                <Label className="form-label" for="validationCustom02">{Industry}</Label>
                                <Controller
                                    name="industry"
                                    control={control} // Set the initial value to null
                                    render={({ field }) => (
                                        <Typeahead
                                            options={industryOption}
                                            onChange={(value) => {
                                                field.onChange(value); // Update the value in react-hook-form
                                            }}
                                            value={field.value}
                                        />
                                    )}
                                />
                                <span className="text-danger">{errors.industry && IndustryError}</span>
                            </Col>
                        </Row>
                        <Row className="g-3 pt-3">
                            <Col md='6'>
                                <Label className="form-label" for="validationCustom01">{Price}</Label>
                                <input className="form-control" id="validationCustom01" type="tel" {...register('price', { required: true })} />
                                <span className="text-danger">{errors.price && PriceError}</span>
                            </Col>
                            <Col md="6">
                                <Label className="form-label" for="validationCustom02">{MRP}</Label>
                                <input className="form-control" id="validationCustom02" type="text" {...register('mrp', { required: true })} />
                                <span className="text-danger">{errors.mrp && MrpError}</span>
                            </Col>
                        </Row>
                        <Row className="g-3">
                            <Col>
                                <Label className="form-label pt-3">{DiscriptionText}</Label>
                                <Controller
                                    name="message"
                                    control={control}
                                    render={({ field }) => (
                                        <textarea
                                            className="form-control"
                                            {...field}
                                            rows="3" // Set the 'rows' attribute to 3 here
                                        />
                                    )}
                                />
                            </Col>
                        </Row>
                        <FormGroup>
                        </FormGroup>
                        <Btn attrBtn={{ color: "primary" }} type="submit">{SubmitForm}</Btn>
                    </Form>
                </CardBody>
            </Card>
        </Fragment>
    )
}
export default UserAddressForm;