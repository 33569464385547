import React, { Fragment, useEffect, useState } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row } from 'reactstrap';
import { Btn, H3, Breadcrumbs } from "../../../AbstractElements";
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { getIndustryData, addTaxGroup, getUsers } from "../../../CommonURL";
import { getDataWithToken, postDataWithToken } from "../../../Utils/restUtils";
import SpinnerLoader from "../../../Layout/SpinnerLoader";
import Typeahead from "../../../CommonElements/TypeAhead";
import Standards from "../../App/Ecommerce/PricingMemberShip/Standard";
import { AddSubscription, Industry, Packages, SubmitForm, Users } from "../../../Constant";

const SubscriptionForm = () => {
    const { register, handleSubmit, control, formState: { errors } } = useForm();
    const [industry, setIndustry] = useState([]);
    const [industryOption, setIndustryOption] = useState([]);
    const [users, setUsers] = useState([]);
    const [usersOption, setUsersOption] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        industryData(); // do not put await here
    }, []);

    useEffect(() => {
        setIndustryOption(industry.map((item) => ({
            value: item.id,
            label: item.name,
        })));
    }, [industry]);

    const industryData = async () => {
        const data = await getDataWithToken(getIndustryData);
        setIndustry(data.data);
    };

    // Fetch users based on the selected industry
    const fetchUsers = async (industryId) => {
        const data = await postDataWithToken(getUsers, industryId);
        setUsers(data.data.data);
        setUsersOption(data.data.data.map((item) => ({
            value: item.id,
            label: item.user_name,
        })));
    };

    const onSubmit = async (data) => {
        setLoading(true);
        const postRequest = {
            title: data.name,
            industry_type_id: data.industry.value,
            description: data.message
        };
        const addTaxResponse = await postDataWithToken(addTaxGroup, postRequest);
        if (addTaxResponse.data.message === 'success') {
            navigate(`${process.env.PUBLIC_URL}/editor/tax-list`);
        } else {
            // Handle errors
        }
        setLoading(false);
    };

    return (
        <Fragment>
            {loading && (
                <SpinnerLoader />
            )}

            <Breadcrumbs mainTitle='Add Subscription' parent="Configuration" title="Subscription" />
            <Card>
                <CardHeader className="pb-0">
                    <H3>{AddSubscription}</H3>
                </CardHeader>
                <CardBody>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Row className="g-3">
                            <Col md="6">
                                <Label className="form-label" for="validationCustom02">{Industry}</Label>
                                <Controller
                                    name="industry"
                                    control={control}
                                    render={({ field }) => {(
                                        <Typeahead
                                            options={industryOption}
                                            onChange={(value) => {
                                                field.onChange(value);
                                                // Fetch users based on the selected industry
                                                fetchUsers(value.value);
                                            }}
                                            value={field.value}
                                        />
                                    )}}
                                />
                                <span className="text-danger">{errors.industry && IndustryError}</span>
                            </Col>
                            <Col md="6">
                                <Label className="form-label" for="validationCustom02">{Users}</Label>
                                <Controller
                                    name="users"
                                    control={control}
                                    render={({ field }) => (
                                        <Typeahead
                                            options={usersOption}
                                            onChange={(value) => {
                                                field.onChange(value);
                                            }}
                                            value={field.value}
                                        />
                                    )}
                                />
                                <span className="text-danger">{errors.users && LastNameError}</span>
                            </Col>
                        </Row>
                        <Row className="g-3">
                            <Col>
                                <Label className="form-label pt-3">{Packages}</Label>
                                <Standards />
                            </Col>
                        </Row>
                        <FormGroup>
                        </FormGroup>
                        <Btn attrBtn={{ color: "primary" }} type="submit">{SubmitForm}</Btn>
                    </Form>
                </CardBody>
            </Card>
        </Fragment>
    )
}

export default SubscriptionForm;
