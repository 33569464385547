import React, { Fragment, useState, useEffect } from 'react';
import { Col, Card, CardHeader, CardBody, Row, Modal, ModalHeader, ModalBody, Label, FormGroup, Form } from 'reactstrap';
import { OrderHistoryColumns, RoleColumns } from '../../../Data/MockTable/TableHeaderList';
import SweetAlert from 'sweetalert2';
import DataTable from 'react-data-table-component';
import { H3, Btn, Breadcrumbs } from '../../../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';
import { deleteAccount, deleteRole, getAccount, getRole } from '../../../CommonURL';
import { getDataWithToken, postDataWithToken } from '../../../Utils/restUtils';
import { Add } from '../../../Constant';
import SearchList from '../../../CommonElements/Search';
import Transparentspinner from '../../Common/loader/Transparentspinner';

const RoleList = () => {
    const style2 = { width: 60, fontSize: 13, padding: 3, marginRight: '10px' };
    const [roleList, setRoleList] = useState([])
    const [loading, setLoading] = useState(false);
    const [roleNameData, setRoleNameData] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const navigate = useNavigate();
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);


    useEffect(() => {
        roleData(); // do not put await here
    }, [searchKeyword]);

    const filteredItems = roleNameData.filter(
        (item) => item.name && item.name.toLowerCase().includes(searchKeyword.toLowerCase()) 
    );

    const updateSearchKeyword = (keyword) => {
        setSearchKeyword(keyword);
    };

    const redirectToEditForm = (data) => {
        const editFormURL = `${process.env.PUBLIC_URL}/master-records/role-edit?id=${data.id}`;
        navigate(editFormURL);
    };

    const deleteRoleList = (userId) => {
        SweetAlert.fire({
            title: 'Are you sure?',
            text: 'Once deleted, you will not be able to recover this imaginary file!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'cancel',
            reverseButtons: true
        }).then(async (result) => {
            setLoading(true);
            if (result.value) {
                const deleteRes = { role_id: userId }
                const roleDelete = await postDataWithToken(deleteRole, deleteRes);
                if (roleDelete.data.status === true) {
                    SweetAlert.fire(
                        'Deleted!',
                        'Your file has been deleted.',
                        'success'
                    );
                    industryData();
                } else {
                    setLoading(false);
                    navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
                }
            } else {

            }
            setLoading(false);
        });
    };
    
    const roleData = async () => {
        setLoading(true);
        let data = await getDataWithToken(getRole);
        setResetPaginationToggle(!resetPaginationToggle);
        setRoleNameData(data.data)
        if (searchKeyword === '') {
            setRoleList(data.data.map((e, index) => {
                return {
                    Si: index + 1,
                    Name: <span>{e.name}</span>,
                    action:
                        <div>
                            <span>
                                <Btn attrBtn={{ style: style2, color: 'primary btn-xs', type: 'button', onClick: () => redirectToEditForm(e) }}>Edit </Btn>
                            </span>
                            <span>
                                <Btn attrBtn={{ style: style2, className: 'me-2', color: 'danger btn-xs', type: 'button', onClick: () => { deleteRoleList(e.id) } }}>Delete</Btn>
                            </span>
                        </div >
                }
            }))
        } else {
            setRoleList(filteredItems.map((e, index) => {
                return {
                    Si: index + 1,
                    Name: <span>{e.name}</span>,
                    action:
                        <div>
                            <span>
                                <Btn attrBtn={{ style: style2, color: 'primary btn-xs', type: 'button', onClick: () => redirectToEditForm(e) }}>Edit </Btn>
                            </span>
                            <span>
                                <Btn attrBtn={{ style: style2, className: 'me-2', color: 'danger btn-xs', type: 'button', onClick: () => { deleteRoleList(e.id) } }}>Delete</Btn>
                            </span>
                        </div >
                }
            }))
        }
        setLoading(false);
    };
    return (
        <Fragment>
            {loading && <Transparentspinner />}
            <Breadcrumbs mainTitle='Role' parent="Administration" title="Role" />
            <Card>
                <CardHeader>
                    <H3>Role Table</H3>
                </CardHeader>
                <div style={{ paddingLeft: '22px', marginRight: '22px', marginBottom: '-20px' }}>
                    <div style={{ float: 'left' }}>
                        <SearchList updateSearchKeyword={updateSearchKeyword} />
                    </div>
                    <div style={{ float: 'right' }}>
                        <Link to={`${process.env.PUBLIC_URL}/master-records/role-add`} className="btn btn-primary">{Add}</Link>
                    </div>
                    <div style={{ clear: 'both' }}></div>
                </div>
                <CardBody>
                    <div className="order-history table-responsive">
                        <DataTable
                            data={roleList}
                            columns={RoleColumns}
                            pagination
                            style={{ border: '1px solid' }}
                        />
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    );
};

export default RoleList;