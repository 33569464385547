import React, { Fragment, useEffect, useState } from "react";
import { Card, CardBody, Col } from "reactstrap";
import { H2, H6 } from "../../../AbstractElements";
import { EarningsSvg, MessagesSvg, NewUserSvg, ProductsSvg } from "../../../Data/Widgets/General/Data";
import CountUp from 'react-countup';
import { getStaticWidget } from "../../../CommonURL";
import { getDataWithToken } from "../../../Utils/restUtils";

const StaticWidgets = ({ param1 }) => {
    const [widgetDetails, setWidgetDetails] = useState({});
    const StaticWidgetsData = [
        {
            id: 1,
            title: 'Active Customers',
            counter: widgetDetails.active_customers,
            svg: <EarningsSvg />,
            class: 'progress-gradient-secondary',
            progress: '75%'
        },
        {
            id: 2,
            title: 'Active Connections',
            counter: widgetDetails.active_connections,
            svg: <ProductsSvg />,
            class: 'progress-gradient-success',
            progress: '60%'
        },
        {
            id: 3,
            title: 'Unpaid Invoices',
            counter: widgetDetails.unpaid_amount,
            svg: <MessagesSvg />,
            class: 'progress-gradient-primary',
            progress: '48%'
        },
        {
            id: 4,
            title: 'Amount Paid',
            counter: widgetDetails.paid_amount,
            svg: <NewUserSvg />,
            class: 'progress-gradient-danger',
            progress: '48%'
        },
        {
            id: 5,
            title: 'Wallet Money',
            counter: widgetDetails.wallet_amount,
            svg: <MessagesSvg />,
            class: 'progress-gradient-primary',
            progress: '48%'
        },
        {
            id: 6,
            title: 'Industry Accounts',
            counter: widgetDetails.industry_accounts,
            svg: <NewUserSvg />,
            class: 'progress-gradient-danger',
            progress: '48%'
        },
    ]

    useEffect(() => {
        widgetData();
    }, [])

    const widgetData = async () => {
        let response = await getDataWithToken(getStaticWidget + `?user_id=${param1}`);
        setWidgetDetails(response.data);
    }

    return (
        <Fragment>
            {StaticWidgetsData.map((data) => {
                return (
                    <Col sm="4" lg="4" key={data.id}>
                        <Card className="o-hidden" style={{ marginright: '140rem' }}>
                            <CardBody>
                                <div className="d-flex static-widget">
                                    <div className="flex-grow-1">
                                        <H6 className="font-roboto">{data.title}</H6>
                                        <H2 attrH2={{ className: 'mb-0 counter' }}><CountUp end={data.counter} duration={5} /></H2>
                                    </div>
                                    {data.svg}
                                </div>
                                <div className="progress-widget">
                                    <div className="progress sm-progress-bar progress-animate">
                                        <div className={`${data.class}`} role="progressbar" style={{ width: `${data.progress}` }} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                                            <span className="animate-circle"></span>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                )
            })}
        </Fragment>
    );
};

export default StaticWidgets;