import React, { Fragment, useState } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row } from 'reactstrap';
import { CityError, AddIndustry, Comment, Name, NameError, IconError, SubmitForm } from '../../../../Constant';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Btn, H3 } from "../../../../AbstractElements";
import { postDataWithToken } from "../../../../Utils/restUtils";
import { addIndustryURL } from "../../../../CommonURL";
import SpinnerLoader from "../../../../Layout/SpinnerLoader";
import { toast } from 'react-toastify';

const IndustryAddContainer = () => {
    const { register, handleSubmit, control, formState: { errors } } = useForm();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const formData = new FormData();

    const onSubmit = async (data) => {
        console.log('data',data)
        setLoading(true);
        formData.append('name', data.name);
        formData.append('client_url', data.url);
        formData.append('comment', data.message);
        formData.append('icon', data.file[0]);
        console.log('formData',formData);
        const addIndustryResponse = await postDataWithToken(addIndustryURL, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        if (addIndustryResponse.data.message === "success") {
            navigate(`${process.env.PUBLIC_URL}/configuration/industry-list`);
            toast.success('Login Success...!')
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        setLoading(false);
    };
    return (
        <Fragment>
            {loading && (
                <SpinnerLoader />
            )}
            <Card>
                <CardHeader className="pb-0">
                    <H3>{AddIndustry}</H3>
                    {/* <span>{CustomstyleText} <code className="text-danger">{novalidate}</code> {CustomstyleText2} <code className="text-danger">&lt;{form}&gt;</code>{CustomstyleText3}</span><span>{CustomstyleText4} <code className="text-danger">{invalid} </code> {and} <code className="text-danger">{valid} </code> {CustomstyleText5}</span> */}
                </CardHeader>
                <CardBody>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Row className="g-3">
                            <Col md='6'>
                                <Label className="form-label" for="validationCustom01">{Name}</Label>
                                <input className="form-control" id="validationCustom01" type="text"
                                    placeholder="Username"
                                    {...register('name', { required: true })}
                                />
                                <span className="text-danger">{errors.firstname && NameError}</span>
                            </Col>
                            <Col md="6">
                                <Label className="form-label">Icon</Label>
                                <Controller
                                    name="file"
                                    control={control}
                                    render={({ field }) => (
                                        <div style={{display:'flex'}}>
                                        <input
                                            className="form-control"
                                            type="file"
                                            onChange={(e) => field.onChange(e.target.files)}
                                            accept='.png, .jpg, .jpeg'
                                        />
                                        </div>
                                    )}
                                />
                                <span className="text-danger">{errors.icon && IconError}</span>
                            </Col>
                        </Row>
                        <Row className="g-3">
                            <Col md='12'>
                                <Label className="form-label pt-3" for="validationCustom03">URL/Domain Name</Label>
                                <input className="form-control" id="validationCustom03" type="text"
                                    placeholder="https://www.example.com" defaultValue={'https://www.'}
                                    {...register('url', { required: true })}
                                />
                                <span className="text-danger">{errors.city && CityError}</span>
                            </Col>
                        </Row>
                        <Row className="g-3">
                            <Col>
                                <Label className="form-label pt-3">{Comment}</Label>
                                <Controller
                                    name="message"
                                    control={control}
                                    render={({ field }) => (
                                        <textarea
                                            className="form-control"
                                            {...field}
                                            rows="3" // Set the 'rows' attribute to 3 here
                                        />
                                    )}
                                />
                            </Col>
                        </Row>
                        <FormGroup>
                        </FormGroup>
                        <Btn attrBtn={{ color: "primary" }} type="submit">{SubmitForm}</Btn>
                    </Form>
                </CardBody>
            </Card>
        </Fragment>
    )
}

export default IndustryAddContainer;