import React, { Fragment, useState, useEffect } from 'react';
import { Col, Card, CardHeader, CardBody, Row, Modal, ModalHeader, ModalBody, Label, FormGroup, Form, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
// import { OrderHistoryColumns } from '../../../Data/MockTable/TableHeaderList';
import SweetAlert from 'sweetalert2';
import DataTable from 'react-data-table-component';
import { H3, Btn, Breadcrumbs, H4 } from '../../../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';
import { deleteAccount, getAccount } from '../../../CommonURL';
import { postDataWithToken } from '../../../Utils/restUtils';
import { Add } from '../../../Constant';
import SearchList from '../../../CommonElements/Search';
import Transparentspinner from '../../Common/loader/Transparentspinner';

const AccountList = () => {
    const style2 = { width: 60, fontSize: 13, padding: 3, marginRight: '10px' };
    const toggleStyle = { padding: '10px 20px', opacity: '0.8', fontSize: '14px' };

    const [accountList, setAccountList] = useState([])
    const [accountData, setAccountData] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const OrderHistoryColumns = [
        {
          name: <H4>Sl</H4>,
          selector: row => row.Si,
          sortable: true,
          width: '50px',
        },
        {
            name: <i className="fa fa-gears" style={{ fontSize: '15px' }}></i>,
            selector: row => row.action,
            cell: row => <ActionCell row={row} />,
            width: '55px',
             center: true,
        },
        {
          name: <H4>Account Name</H4>,
          selector: row => row.AccountName,
          sortable: true,
        },
        {
          name: <H4>Opening Balance</H4>,
          selector: row => row.OpeningBalance,
          sortable: true,
        },
        {
          name: <H4>Bank Name</H4>,
          selector: row => row.BankName,
          sortable: true,
        },
        {
          name: <H4>Bank Phone no.</H4>,
          selector: row => row.Bankno,
          sortable: true,
        },
        {
          name: <H4>Bank Address</H4>,
          selector: row => row.BankAddress,
          sortable: true,
        },
        {
          name: <H4>Account no.</H4>,
          selector: row => row.Accountno,
          sortable: true,
        },
        {
          name: <H4>Added by</H4>,
          selector: row => row.CreatedBy,
          sortable: true,
        }, 
      ];
      
    useEffect(() => {
        industryData(); // do not put await here
    }, [searchKeyword]);

    const filteredItems = accountData.filter(
        (item) => item.name && item.name.toLowerCase().includes(searchKeyword.toLowerCase()) ||
            item.bank_phone && item.bank_phone.toLowerCase().includes(searchKeyword.toLowerCase()) ||
            item.account_number && item.account_number.toLowerCase().includes(searchKeyword.toLowerCase()) ||
            item.bank_name && item.bank_name.toLowerCase().includes(searchKeyword.toLowerCase())
    );

    const updateSearchKeyword = (keyword) => {
        setSearchKeyword(keyword);
    };

    const redirectToEditForm = (data) => {
        const editFormURL = `${process.env.PUBLIC_URL}/configuration/account-edit?id=${data.id}`;
        navigate(editFormURL);
    };

    const deleteAccountList = (id) => {
        SweetAlert.fire({
            title: 'Are you sure?',
            text: 'Once deleted, you will not be able to recover this file!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'cancel',
            reverseButtons: true
        }).then(async (result) => {
            setLoading(true)
                if (result.value) {
                const deleteRes = { account_id: id }
                const industryDelete = await postDataWithToken(deleteAccount, deleteRes);
                if (industryDelete.data.message === 'success') {
                    SweetAlert.fire(
                        'Deleted!',
                        'Your file has been deleted.',
                        'success'
                    );
                    industryData();
                } else {
                    setLoading(false)
                    navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
                }
            }
            //  else {

            // }
            setLoading(false)
        });
    };

    const ActionCell = ({ row }) => {
        const [dropdownOpen, setDropdownOpen] = React.useState(false);
        const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

        return (
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle color="#00000" style={{ fontSize: '14px', zIndex: 1000 }}>
                    <i className="fa fa-ellipsis-v"></i>
                </DropdownToggle>
                <DropdownMenu container="body"> 
                    <DropdownItem style={toggleStyle} onClick={() => redirectToEditForm(row)}>Edit</DropdownItem>
                    <DropdownItem style={{padding: '10px 20px', color: 'red', opacity: '0.8', fontSize: '14px', margin: '0'}} onClick={() => deleteAccountList(row.id)}>Delete</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        );
    };


    const industryData = async () => {
        setLoading(true)
        const data = await postDataWithToken(getAccount);
        setResetPaginationToggle(!resetPaginationToggle);
        setAccountData(data.data.data)
        if (searchKeyword === '') {
            setAccountList(data.data.data.map((e, index) => {
                return {
                    Si: index + 1,
                    id:e.id,
                    AccountName: e.name,
                    OpeningBalance: e.opening_balance,
                    BankName: e.bank_name,
                    Bankno: e.bank_phone,
                    BankAddress: e.bank_address,
                    Accountno: e.account_number,
                    CreatedBy: e.created_user,
                    // action:
                    //     <div>
                    //         <span>
                    //             <Btn attrBtn={{ style: style2, color: 'primary btn-xs', type: 'button', onClick: () => redirectToEditForm(e) }}>Edit </Btn>
                    //         </span>
                    //         <span>
                    //             <Btn attrBtn={{ style: style2, className: 'me-2', color: 'danger btn-xs', type: 'button', onClick: () => { deleteAccountList(e.id) } }}>Delete</Btn>
                    //         </span>
                    //     </div >
                }
            }))
        } else {
            setAccountList(filteredItems.map((e, index) => {
                return {
                    Si: index + 1,
                    id:e.id,
                    AccountName: e.name,
                    OpeningBalance: e.opening_balance,
                    BankName: e.bank_name,
                    Bankno: e.bank_phone,
                    BankAddress: e.bank_address,
                    Accountno: e.account_number,
                    CreatedBy: e.created_user,
                    // action:
                    //     <div>
                    //         <span>
                    //             <Btn attrBtn={{ style: style2, color: 'primary btn-xs', type: 'button', onClick: () => redirectToEditForm(e) }}>Edit </Btn>
                    //         </span>
                    //         <span>
                    //             <Btn attrBtn={{ style: style2, className: 'me-2', color: 'danger btn-xs', type: 'button', onClick: () => { deleteAccountList(e.id) } }}>Delete</Btn>
                    //         </span>
                    //     </div >
                }
            }))
        }
        setLoading(false)
    };
    return (
        <Fragment>
            {loading && <Transparentspinner />}
            <Breadcrumbs mainTitle='Accounts' parent="Configuration" title="Accounts" />
            <Card>
                <CardHeader>
                    <H3>Account List</H3>
                </CardHeader>
                <div style={{ paddingLeft: '22px', marginRight: '22px', marginBottom: '-20px' }}>
                    <div style={{ float: 'left' }}>
                        <SearchList updateSearchKeyword={updateSearchKeyword} />
                    </div>
                    <div style={{ float: 'right' }}>
                        <Link to={`${process.env.PUBLIC_URL}/configuration/account-add`} className="btn btn-primary">{Add}</Link>
                    </div>
                    <div style={{ clear: 'both' }}></div>
                </div>
                <CardBody>
                    <div className="order-history table-responsive">
                        <DataTable
                            data={accountList}
                            columns={OrderHistoryColumns}
                            pagination
                            style={{ border: '1px solid' }}
                        />
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    );
};

export default AccountList;