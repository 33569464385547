import { Total, Item, Amount, Quantity, Tax, Discount, } from '../../Constant';
import { H6, H4, P, H3 } from '../../AbstractElements';
// import InvoiceHeader from './InvoiceHeader';
// import InvoiceDescription from './InvoiceDescription';
import { Col, Label, Row, Container, CardBody, Card, Nav, NavItem, NavLink, TabContent, TabPane, } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { getInvoice } from '../../CommonURL';
import InvoiceTotal from './InvoiceTotal';
import Transparentspinner from '../../Components/Common/loader/Transparentspinner'
import InvoiceHeader from './InvoiceHeader';

const ItemDescription = React.forwardRef((props, ref) => {
    const invoiceDetail = props.idValue;
    const statusColor = props.statusColor;
    const [primarycolorTab, setprimarycolorTab] = useState('1');

    const printSection = () => {
        return (
            <div ref={ref}>
                <div className='invoice invoice-print-styling'>
                    <Row>
                        <div className='mt-5'>
                            <InvoiceHeader prop1={invoiceDetail} />
                            <div className="table-responsive invoice-table" id="table">
                                <table className='table table-bordered'>
                                    <tbody>
                                        <tr>
                                            <th className="item text-left">
                                                {Item}
                                            </th>
                                            <th className="Rate text-end">
                                                {Quantity}
                                            </th>
                                            <th className="Hours text-end">
                                                {Amount}
                                            </th>
                                            <th className="Hours text-end">
                                                {Discount}
                                            </th>
                                            <th className="subtotal text-end">
                                                {Total}
                                            </th>
                                        </tr>
                                        {invoiceDetail.order_item?.map((item) => {
                                            return (
                                                <tr key={item.id}>
                                                    <td>
                                                        <Label className='mb-2'>{item.name}</Label>
                                                    </td>
                                                    <td className=' text-end'>
                                                        <P attrPara={{ className: 'itemtext digits' }}>{item.quantity}</P>
                                                    </td>
                                                    <td className=' text-end'>
                                                        <P attrPara={{ className: 'itemtext digits' }}>₹ {item.unit_price}</P>
                                                    </td>
                                                    <td className=' text-end'>
                                                        <P attrPara={{ className: 'itemtext digits' }}>{item.discount ?? 0}</P>
                                                    </td>
                                                    <td className=' text-end' >
                                                        <P attrPara={{ className: 'itemtext digits' }}>₹ {item.unit_price}</P>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <InvoiceTotal item={invoiceDetail} />
                        </div>
                    </Row>
                </div>
            </div>
        )
    }

    return (
        <Container>
            <Row>
                <Col sm="12" xl="6 xl-100">
                    <Card>
                        <CardBody>
                            <Nav className="nav-primary" tabs>
                                <NavItem>
                                    <NavLink className={primarycolorTab === '1' ? 'active' : ''} style={{
                                        background: primarycolorTab === '1' ? statusColor : 'transparent',
                                    }}
                                        onClick={() => setprimarycolorTab('1')}>
                                        {invoiceDetail.payment_status}
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={primarycolorTab}>
                                <TabPane tabId="1">
                                    {printSection()}
                                </TabPane>
                            </TabContent>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
});

export default ItemDescription;