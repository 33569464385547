import React, { Fragment, useEffect, useState } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row } from 'reactstrap';
import { Btn, H3, Breadcrumbs } from "../../../AbstractElements";
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import { useLocation, useNavigate } from 'react-router-dom';
import { getIndustryData, addPackages, getTaxGroup, getPackages, uploadPackages } from "../../../CommonURL";
import { getDataWithToken, postDataWithToken } from "../../../Utils/restUtils";
import { DiscriptionText, Name, NameError, SubmitForm, AddPackages, Features, Price, MRP, TaxGroup, Industry, SetupCost, ExpiryDate, Days, FeaturesError, PriceError, MrpError, TaxGroupError, IndustryError, SetupCostError, ExpiryDateError, DaysError, HSN, EditPackages, MinConnection, MaxConnection, PriceCalculation, SMSActive, TaxClass } from '../../../Constant';
import Typeahead from "../../../CommonElements/TypeAhead";
import SpinnerLoader from "../../../Layout/SpinnerLoader";
import Transparentspinner from "../../Common/loader/Transparentspinner";

const PackagesEdit = () => {
    const location = useLocation();
    const searchParams = location.search;
    const params = new URLSearchParams(searchParams);
    const param1 = params.get("id");
    const { register, handleSubmit, control, formState: { errors }, setValue } = useForm({
        defaultValues: {}
    });
    const [smsCheck, setSmsCheck] = useState(new Date());
    const [industry, setIndustry] = useState([]);
    const [tax, setTax] = useState([]);
    const [taxOption, setTaxOption] = useState([]);
    const [industryOption, setIndustryOption] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const priceCalOptions = [
        { value: 'fixed', label: 'Fixed Amount' },
        { value: 'per_unit', label: 'Per Unit' },
    ];

    useEffect(() => {
        industryData();
        taxData();
        getPackageData();
    }, []);

    useEffect(() => {
        setTaxOption(tax.map((item) => ({
            value: item.id,
            label: item.title,
        })));
        setIndustryOption(industry.map((item) => ({
            value: item.id,
            label: item.name,
        })));
    }, [tax, industry]);

    const industryData = async () => {
        const data = await getDataWithToken(getIndustryData);
        setIndustry(data.data);
    };

    const taxData = async () => {
        const getTaxdata = await getDataWithToken(getTaxGroup);
        setTax(getTaxdata.data);
    };

    const getLabelForPriceCalculation = (value) => {
        const option = priceCalOptions.find(option => option.value === value);
        return option ? option.label : ''; 
    };

    const getPackageData = async () => {
        setLoading(true);
        let data = await getDataWithToken(getPackages + `?package_id=${param1}`);
        setValue('name', data.data[0].name);
        setValue('features', data.data[0].features);
        setValue('price', data.data[0].price);
        setValue('mrp', data.data[0].mrp);
        setValue('expDate', data.data[0].duration);
        setValue('minCon', data.data[0].min_connection);
        setValue('maxCon', data.data[0].max_connection)
        setValue('hsn', data.data[0].hsn_no);
        setValue('setupCost', data.data[0].setup_cost);
        setValue('taxClass', { label: data.data[0].tax_group_name, value: data.data[0].tax_group_id });
        setValue('industry', { label: data.data[0].industry_name, value: data.data[0].industry_type_id });
        setValue('priceCal', { label: getLabelForPriceCalculation(data.data[0].price_calculation), value: data.data[0].price_calculation });
        const isSmsOn = data.data[0].sms === 'on';
        setSmsCheck(isSmsOn);
        setValue('sms', isSmsOn);
        setLoading(false)
    }

    const onSubmit = async (data) => {
        setLoading(true);
        const postRequest = { package_id: param1, name: data.name, price: data.price, mrp: data.mrp, tax_group_id: data.taxClass.value, industry_type_id: data.industry.value, features: data.features, setup_cost: data.setupCost, duration: data.expDate, min_connection: data.minCon, hsn_no: data.hsn, max_connection: data.maxCon, price_calculation: data.priceCal.value, sms: data.sms }
        const editPackageResponse = await postDataWithToken(uploadPackages, postRequest);
        if (editPackageResponse.data.message === 'success') {
            toast.success('Success...!')
            navigate(`${process.env.PUBLIC_URL}/configuration/packages`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        setLoading(false);
    };
    return (
        <Fragment>
            {loading && (
                <Transparentspinner />
            )}

            <Breadcrumbs mainTitle='Edit Packages' parent="Configuration" title="Packages" />
            <Card>
                <CardHeader className="pb-0">
                    <H3>{EditPackages}</H3>
                    {/* <span>{CustomstyleText} <code className="text-danger">{novalidate}</code> {CustomstyleText2} <code className="text-danger">&lt;{form}&gt;</code>{CustomstyleText3}</span><span>{CustomstyleText4} <code className="text-danger">{invalid} </code> {and} <code className="text-danger">{valid} </code> {CustomstyleText5}</span> */}
                </CardHeader>
                <CardBody>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Row className="g-3">
                            <Col md="6">
                                <Label className="form-label" >{Industry}</Label>
                                <Controller
                                    name="industry"
                                    control={control} // Set the initial value to null
                                    render={({ field }) => (
                                        <Typeahead
                                            options={industryOption}
                                            onChange={(value) => {
                                                field.onChange(value); // Update the value in react-hook-form
                                            }}
                                            value={field.value}
                                        />
                                    )}
                                />
                                <span className="text-danger">{errors.industry && IndustryError}</span>
                            </Col>
                            <Col md='6'>
                                <Label className="form-label" for="name">{Name}</Label>
                                <input className="form-control" id="name" type="text" {...register('name', { required: true })} />
                                <span className="text-danger">{errors.name && NameError}</span>
                            </Col>
                            <Col md='6'>
                                <Label className="form-label" for="hsn">{HSN}</Label>
                                <input className="form-control" id="hsn" type="text" {...register('hsn', { required: true })} />
                                <span className="text-danger">{errors.days && DaysError}</span>
                            </Col>
                            <Col md='6'>
                                <Label className="form-label" for="mincon">{MinConnection}</Label>
                                <input className="form-control" id="mincon" type="number" min="0" {...register('minCon', { required: true })} />
                                <span className="text-danger">{errors.price ? PriceError : ''}</span>
                            </Col>
                            <Col md="6">
                                <Label className="form-label" for="maxcon">{MaxConnection}</Label>
                                <input className="form-control" id="maxcon" type="number" min="0" {...register('maxCon', { required: true })} />
                                <span className="text-danger">{errors.mrp && MrpError}</span>
                            </Col>
                            <Col md='6'>
                                <Label className="form-label" >{PriceCalculation}</Label>
                                <Controller
                                    name="priceCal"
                                    control={control} // Set the initial value to null
                                    render={({ field }) => (
                                        <Typeahead
                                            options={priceCalOptions}
                                            onChange={(value) => {
                                                field.onChange(value); // Update the value in react-hook-form
                                            }}
                                            value={field.value}
                                        />
                                    )}
                                />
                                <span className="text-danger">{errors.setupCost ? SetupCostError : ''}</span>
                            </Col>
                            <Col md='6'>
                                <Label className="form-label" for="price">{Price}</Label>
                                <input className="form-control" id="price" type="text" {...register('price', { required: true })} />
                                <span className="text-danger">{errors.price && PriceError}</span>
                            </Col>
                            <Col md="6">
                                <Label className="form-label" for="mrp">{MRP}</Label>
                                <input className="form-control" id="mrp" type="text" {...register('mrp', { required: true })} />
                                <span className="text-danger">{errors.mrp && MrpError}</span>
                            </Col>
                            <Col md="6">
                                <Label className="form-label" for="setupcost">{SetupCost}</Label>
                                <input className="form-control" id="setupcost" type="number" min="0" {...register('setupCost', { required: true })} />
                                <span className="text-danger">{errors.mrp && MrpError}</span>
                            </Col>
                            <Col md='6'>
                                <Label className="form-label" >{TaxClass}</Label>
                                <Controller
                                    name="taxClass"
                                    control={control} // Set the initial value to null
                                    render={({ field }) => (
                                        <Typeahead
                                            options={taxOption}
                                            onChange={(value) => {
                                                field.onChange(value); // Update the value in react-hook-form
                                            }}
                                            value={field.value}
                                        />
                                    )}
                                />
                                {/* <span className="text-danger">{errors.taxGroup ? TaxGroupError : ''}</span> */}
                            </Col>
                            <Col md="6">
                                <FormGroup className="mb-3">
                                    <Label className="form-label" for="expdate">{ExpiryDate}</Label>
                                    <input className="form-control" id="expdate" type="number" min="0" {...register('expDate', { required: true })} />
                                    <span className="text-danger">{errors.mrp && MrpError}</span>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="g-3">
                            <Col md='6'>
                                <div className="form-check checkbox checkbox-primary mb-0">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="sms"
                                        {...register('sms')} // Ensure 'sms' is registered with react-hook-form
                                        defaultChecked={smsCheck} // Ensure correct initial state
                                    />
                                    <label className="form-check-label" htmlFor="sms">{SMSActive}</label>
                                </div>
                            </Col>
                        </Row>
                        <Row className="g-3">
                            <Col>
                                <Label className="form-label pt-3">{Features}</Label>
                                <Controller
                                    name="features"
                                    control={control}
                                    render={({ field }) => (
                                        <textarea
                                            className="form-control"
                                            {...field}
                                            rows="3" // Set the 'rows' attribute to 3 here
                                        />
                                    )}
                                />
                            </Col>
                        </Row>
                        <FormGroup>
                        </FormGroup>
                        <Btn attrBtn={{ color: "primary" }} type="submit">{SubmitForm}</Btn>
                    </Form>
                </CardBody>
            </Card>
        </Fragment>
    )
}
export default PackagesEdit;