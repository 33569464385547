import React, { Fragment, useState, useEffect } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row } from 'reactstrap';
import { Btn, H3, Breadcrumbs } from "../../../AbstractElements";
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { editSubscription, updateSubscription} from "../../../CommonURL";
import { getDataWithToken, postDataWithToken } from "../../../Utils/restUtils";
import { EditAccount, StartDate, EndDate, MinmumConnection, MaximumConnection, Price, Submit } from '../../../Constant';
import { toast } from 'react-toastify';
import Transparentspinner from "../../Common/loader/Transparentspinner";
import DatePicker from 'react-datepicker';
import moment from "moment";


const SubscriptionEdit = () => {
    const location = useLocation();
    const searchParams = location.search;
    const params = new URLSearchParams(searchParams);
    const param1 = params.get("id");

    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState();
    const [enddate, setEndDate] = useState(new Date());

    const navigate = useNavigate();

    useEffect(() => {
        getAccountData();
    }, []);

    const handleStartChangeDate = date => {
        setStartDate(date);
        setEndDate(date);
    };

    const handleEndDate = date => {
        setEndDate(date);
    };


    const getAccountData = async () => {
        setLoading(true);
        let data = await getDataWithToken(editSubscription + `?subscription_id=${param1}`);
        setStartDate(new Date(data.data.start_date));
        setEndDate(new Date(data.data.end_date));
        setValue('min_con', data.data.min_connection);
        setValue('max_con', data.data.max_connection);
        setValue('price', data.data.price);
        setLoading(false);
    }

    const onSubmit = async (data) => {
        setLoading(true);
        const postRequest = {
            subscription_id: param1,
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date_date: moment(enddate).format("YYYY-MM-DD"),
            min_connection: data.min_con,
            max_connection: data.max_con,
            price:data.price,
        };
        const updateSubResponse = await postDataWithToken(updateSubscription, postRequest);

        if (updateSubResponse.data.status) {
            navigate(`${process.env.PUBLIC_URL}/billing/subscription`);
            toast.success(updateSubResponse.data.message)
        } else {
            toast.error(updateSubResponse.data.message)
            setLoading(false);
        }
        setLoading(false);
    };
    return (
        <Fragment>
            {loading && (
                <Transparentspinner />
            )}

            <Breadcrumbs mainTitle={EditAccount} parent="Configuration" title="Account" />
            <Card>
                <CardHeader className="pb-0">
                    <H3>{EditAccount}</H3>
                </CardHeader>
                <CardBody>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Row className="g-3 pt-3">
                            <Col md="6">
                                <Label className="form-label">
                                    {StartDate}
                                </Label>
                                <DatePicker
                                    style={{ padding: '12px 100px' }}
                                    className="form-control "
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="dd/mm/yyyy"
                                    selected={startDate}
                                    onChange={handleStartChangeDate}
                                    required
                                />
                            </Col>
                            <Col md="6">
                                <Label className="form-label"   >
                                    {EndDate}
                                </Label>
                                <DatePicker
                                    style={{ padding: '12px 100px' }}
                                    className="form-control "
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="dd/mm/yyyy"
                                    selected={enddate}
                                    onChange={handleEndDate}
                                    required
                                />
                            </Col>
                        </Row>
                        <Row className="g-3 pt-3">
                            <Col md='6'>
                                <Label className="form-label" for="min_connection">{MinmumConnection}</Label>
                                <input className="form-control" id="min_connection" type="text"
                                    {...register('min_con', { required: true })}
                                />
                            </Col>
                            <Col md="6">
                                <Label className="form-label" for="max_connection">{MaximumConnection}</Label>
                                <input className="form-control" id="max_connection" type="text"
                                    {...register('max_con', { required: true })}
                                />
                            </Col>
                        </Row>
                        <Row className="g-3 pt-3">
                            <Col md='6'>
                                <Label className="form-label" for="price">{Price}</Label>
                                <input className="form-control" id="price" type="text"
                                    {...register('price', { required: true })}
                                />
                            </Col>
                        </Row>
                        <FormGroup> 
                        </FormGroup> 
                        <Btn attrBtn={{ color: "primary" }} type="submit">{Submit}</Btn>
                    </Form>
                </CardBody>
            </Card>
        </Fragment>
    )
}

export default SubscriptionEdit;
