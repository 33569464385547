// export const OperatorUrl='http://localhost:3001/loading';
// vercel
//export const OperatorUrl = 'https://biller-q-company-react.vercel.app/loading';
// Live
export const OperatorUrl='https://billerq.app/loading';
// Public Url
export const getInvoice = 'get-shared-invoice';
export const getIndustry = "get-industry";
export const getGeneratePayment = "generate-public-payment";
export const getMakePayment = "make-public-payment";
export const postMakePayment = "store-local-payment";
export const getOtp = "generate-login-otp";

export const adminLogin = "admin/login";
export const getIndustryData = "admin/get-industry-data";
export const deleteIndustry = "admin/remove-industry";
export const uploadIndustry = "admin/update-industry";
export const addIndustryURL = 'admin/add-industry';
export const statusChangeIndustry = "admin/change-status";
export const addTaxGroup = "admin/add-tax-group";
export const addTaxSub = "admin/add-tax";
export const getTaxGroup = "admin/get-tax-groups";
export const uploadTaxGroup = "admin/update-tax-group";
export const statusTaxGroup = "admin/change-tax-group-status";
export const deleteTaxGroup = "admin/remove-tax-group";
export const postTaxData = "admin/tax-data";
export const getTaxEdit = "admin/edit-tax";
export const uploadTax = "admin/update-tax";
export const statusTax = "admin/change-tax-status";
export const deleteTax = "admin/remove-tax";
export const getAccount = "admin/show-account";
export const postAddAccount = "admin/add-account";
export const uploadAccount = "admin/update-account";
export const deleteAccount = "admin/remove-account";
export const getPaymentList = "admin/get-payment_list";
export const getInvoiceList = "admin/get-recent-invoices";
export const postShowTemp = "admin/show-temp-users";
export const getIndustryUser = "admin/get-industry-users";
export const getStaticWidget = "admin/get-user-profile-data";
export const getPackages = "admin/get-packages";
export const addPackages = "admin/add-package";
export const postExtendDays = "admin/update-skip-due";
export const uploadPackages = "admin/update-package";
export const statusChangePackage = "admin/change-package-status";
export const deletePackage = "admin/remove-package";
export const getShowOrder = "admin/show-order";
export const getShareApi = "admin/get-order-number";
export const getInvoiceDetail = "admin/get-invoice-data";
export const getUserSubscription = "admin/show-user-subscription";
export const getCompanyData = "admin/get-user-company-data";
export const getUsers = "admin/get-user-for-select";
export const getRole = "admin/get-roles";
export const deleteRole = "admin/delete-role";
export const addRole = "admin/add-roles";
export const getPermission = "admin/show-permission";
export const getAddons = "admin/get-add-ons";
export const deleteAddons = "admin/remove-add-on";
export const addAddons = "admin/add-add-on";
export const editAddons = "admin/update-add-on";
export const getAddress = "admin/show-address";
export const getPersonalInfo = "admin/edit-user";
export const deleteAddress = "admin/remove-address";
export const getPayment = "admin/view-payment";
export const getSingleOrderDetail = "admin/get-single-order-detail";
export const getPaymentMethod = "admin/get-payment-method-for-select";
export const getAccountOption = "admin/get-account-for-select";
export const addCoupon = "admin/add-coupon";
export const getCoupon = "admin/get-coupon";
export const getDashboardCounts = "admin/total-counts";
export const editCoupon = "admin/edit-coupon";
export const getStatus = "admin/change-coupon-status";
export const updateCoupon = "admin/update-coupon";
export const deleteCoupon = "admin/delete-coupon";
export const showPaymentMethod = "admin/show-payment-methods ";
export const addPaymentMethod = "admin/add-payment-method";
export const getAccountSelect = "admin/get-account-for-select";
export const editPaymentMethod = "admin/edit-payment-method";
export const updatePaymentMethod = "admin/update-payment-method";
export const deletePaymentMethod = "admin/remove-payment-method";
export const editSubscription = "admin/edit-subscription";
export const updateSubscription = "admin/update-subscription";
export const updatePersonalInfo = "admin/update-user-data";
export const createInvoice = 'admin/generate-invoice';