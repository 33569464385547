import React, { Fragment, useEffect, useState } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row } from 'reactstrap';
import { Btn, H3, Breadcrumbs } from "../../../AbstractElements";
import { useForm, Controller } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { getIndustryData, addTaxGroup, addTaxSub, postTaxData } from "../../../CommonURL";
import { getDataWithToken, postDataWithToken } from "../../../Utils/restUtils";
import { AddTax, DiscriptionText,  Name, NameError, Industry, LastNameError, SubmitForm, TaxPer } from '../../../Constant';
import SpinnerLoader from "../../../Layout/SpinnerLoader";
import Transparentspinner from "../../Common/loader/Transparentspinner";

const TaxAddForm = () => {
    const location = useLocation();
    const searchParams = location.search;
    const params = new URLSearchParams(searchParams);
    const param1 = params.get("id");
    const { register, handleSubmit, control, formState: { errors } } = useForm();
    const [taxTitle,setTaxTitle]=useState('');
    const [loading,setLoading]=useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        taxData(); // do not put await here
    }, []);

    const taxData = async () => {
        const taxId = { tax_group_id: param1 };
        const data = await postDataWithToken(postTaxData, taxId);
        setTaxTitle(data.data.data[0]);
    };

    const onSubmit = async (data) => {
        setLoading(true);
        const postRequest={tax_group_id:param1, name:data.name,tax_percentage:data.taxper, description:data.message }
        const addTaxResponse = await postDataWithToken(addTaxSub, postRequest);
        if (addTaxResponse.data.message==='success') {
            // alert('You submitted the form and stuff!');
            navigate(`${process.env.PUBLIC_URL}/configuration/tax-list`);
        } else {
            setLoading(false);
            // errors.showMessages();
        }
        setLoading(false);
    };
    return (
        <Fragment>
            {loading && (
                <Transparentspinner/>
            )}
            
            <Breadcrumbs mainTitle={'Add Tax For '+taxTitle.title} parent="Configuration" title="Tax" />
            <Card>
                <CardHeader className="pb-0">
                    <H3>{AddTax}</H3>
                </CardHeader>
                <CardBody>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Row className="g-3">
                            <Col md='6'>
                                <Label className="form-label" for="validationCustom01">{Name}</Label>
                                <input className="form-control" id="validationCustom01" type="text" placeholder="Username" {...register('name', { required: true })} />
                                <span className="text-danger">{errors.name && NameError}</span>
                            </Col>
                            <Col md="6">
                            <Label className="form-label" for="validationCustom02">{TaxPer}</Label>
                                <input className="form-control" id="validationCustom02" type="text" placeholder="Tax%" {...register('taxper',{ required: true })} />
                                <span className="text-danger">{errors.taxper && LastNameError}</span>   
                            </Col>
                        </Row>
                        <Row className="g-3">
                            <Col>
                                <Label className="form-label pt-3">{DiscriptionText}</Label>
                                <Controller
                                    name="message"
                                    control={control}
                                    render={({ field }) => (
                                        <textarea
                                            className="form-control"
                                            {...field}
                                            rows="3" // Set the 'rows' attribute to 3 here
                                        />
                                    )}
                                />
                            </Col>
                        </Row>
                        <FormGroup>
                        </FormGroup>
                        <Btn attrBtn={{ color: "primary" }} type="submit">{SubmitForm}</Btn>
                    </Form>
                </CardBody>
            </Card>
        </Fragment>
    )
}
export default TaxAddForm;