import { Total, Contact, Modalcontent, Profile, Item, Amount, Quantity, Discount, } from '../../../../Constant';
import { P } from '../../../../AbstractElements';
import InvoiceHeader from './InvoiceHeader';
import { Col, Label, Row, Table, Container, CardBody, Card, Nav, NavItem, NavLink, TabContent, TabPane, } from 'reactstrap';
import React, { useState } from 'react';
import InvoiceTotal from './InvoiceTotal';

const ItemDescription = React.forwardRef((props, ref) => {
    const invoiceDetail=props.invoiceDetail;
    const statusColor = props.statusColor;
    const [primarycolorTab, setprimarycolorTab] = useState('1');

    const printSection = (value) => {
        return (
            <div ref={ref}>
                <div className='invoice invoice-print-styling'>
                    <Row>
                        {/* <Col> */}
                                <div className='mt-5'>
                                    <InvoiceHeader prop1={value} />
                                    {/* <InvoiceDescription prop1={value} /> */}  
                                    <div className="table-responsive invoice-table" id="table">
                                        <table className='table table-bordered'>
                                            <tbody>
                                                <tr>
                                                    <th className="item text-left">
                                                        {Item}
                                                    </th>
                                                    <th className="Rate text-end">
                                                        {Quantity}
                                                    </th>
                                                    <th className="Hours text-end">
                                                        {Amount}
                                                    </th>
                                                    <th className="Hours text-end">
                                                        {Discount}
                                                    </th>
                                                    <th className="subtotal text-end">
                                                        {Total}
                                                    </th>
                                                </tr>
                                                {value.order_item?.map((item) => {
                                                    return (
                                                        <tr key={item.id}>
                                                            <td>
                                                                <Label className='mb-2'>{item.name}</Label>
                                                            </td>
                                                            <td className=' text-end'>
                                                                <P attrPara={{ className: 'itemtext digits' }}>{item.quantity}</P>
                                                            </td>
                                                            <td  className=' text-end'>
                                                                <P attrPara={{ className: 'itemtext digits' }}>₹ {item.unit_price}</P>
                                                            </td>
                                                            <td className=' text-end'>
                                                                <P attrPara={{ className: 'itemtext digits' }}>{item.discount ?? 0}</P>
                                                            </td>
                                                            {/* <td className=' text-end'>
                                                                <P attrPara={{ className: 'itemtext digits' }}>{item.tax} %</P>
                                                            </td> */}
                                                            <td className=' text-end' >
                                                                <P attrPara={{ className: 'itemtext digits' }}>₹ {item.unit_price}</P>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    <InvoiceTotal item = {invoiceDetail}/>
                                </div>
                        {/* </Col> */}
                    </Row>
                </div>
            </div>
        )
    }

    return (
        <Container>
            <Row>
                <Col sm="12" xl="6 xl-100">
                    <Card>
                        <CardBody>
                            <Nav className="nav-primary" tabs>
                                <NavItem>
                                    <NavLink className={primarycolorTab === '1' ? 'active' : ''} style={{
                                        background: primarycolorTab === '1' ? statusColor : 'transparent',
                                    }}
                                        onClick={() => setprimarycolorTab('1')}>
                                        {invoiceDetail.payment_status}
                                    </NavLink>
                                </NavItem>
                                <NavItem >
                                    <NavLink className={primarycolorTab === '2' ? 'active' : ''} onClick={() => setprimarycolorTab('2')}>
                                        {Profile}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={primarycolorTab === '3' ? 'active' : ''} onClick={() => setprimarycolorTab('3')}>
                                        {Contact}
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={primarycolorTab}>
                                <TabPane tabId="1">
                                    {printSection(invoiceDetail)}
                                </TabPane>
                                <TabPane tabId="2">
                                    <P attrPara={{ className: 'mb-0 m-t-30' }} >{Modalcontent}</P>
                                </TabPane>
                                <TabPane tabId="3">
                                    <P attrPara={{ className: 'mb-0 m-t-30' }} >{Modalcontent}</P>
                                </TabPane>
                            </TabContent>
                            {/* <Row>
                                {Object.keys(invoiceDetail).length > 0 && (<ProductPlaceOrder item={invoiceDetail} />)}
                            </Row > */}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
});

export default ItemDescription;