import React, { Fragment, useState, useEffect } from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { H5, H3, LI, P, UL, Btn, Image } from '../../../../AbstractElements';
import { AboutM, Address, Edit, Tax } from '../../../../Constant';
import { Book, Briefcase, Droplet, Heart, MapPin } from 'react-feather';
import { Link, useLocation } from 'react-router-dom';
import CommonModalCancel from '../../../UiKits/Modals/common/modalcncl';
import DataTable from 'react-data-table-component';
import { AddressColumns } from '../../../../Data/MockTable/TableHeaderList';
import { getDataWithToken } from '../../../../Utils/restUtils';
import { getAddress, getPersonalInfo } from '../../../../CommonURL';
import { FaIdCard, FaMoneyBill, FaPencilAlt } from 'react-icons/fa';
import EditProfile from './EditProfile';


const AboutMe = () => {
    const style2 = { width: 60, fontSize: 13, padding: 3, marginRight: '10px' };
    const [addressList, setAddresslist] = useState([]);
    const [personalDetails, setPersonalDetails] = useState('')
    const [isShow, setIsShow] = useState(true);
    const [modal, setModal] = useState(false);
    const location = useLocation();
    const searchParams = location.search;
    const params = new URLSearchParams(searchParams);
    const param1 = params.get("id");
      useEffect(() => {
        handleAddress(); // do not put await here
        handlePersonalAddress();
    }, []);

    const AboutMeData = [
        // {
        //     id: 1,
        //     icon: <i className="icofont icofont-id-card icofont-lg"></i>,
        //     designation: 'Subscriber Id',
        //     place: personalDetails.subscriber_id
        // },
        // {
        //     id: 5,
        //     icon: <i className="icofont icofont-ui-v-card icofont-lg"></i>,
        //     designation: 'LCO No',
        //     place: personalDetails.lco_no
        // },
        {
            id: 2,
            icon: <i className="icofont icofont-phone-circle icofont-lg"></i>,
            designation: 'Contact No',
            place: "+" + personalDetails.country_code + " " +  personalDetails.mobile
        },
        {
            id: 3,
            icon: <i className="icofont icofont-home icofont-lg"></i>,
            designation: 'Address',
            place:personalDetails.city + ","  +  "," + personalDetails.state + "," + personalDetails.country
        },
        {
            id: 4,
            icon: <i className="icofont icofont-location-pin icofont-lg"></i>,
            designation: 'Billing Address',
            place:personalDetails.city + ","  +  "," + personalDetails.state + "," + personalDetails.country
        },

        // {
        //     id: 6,
        //     icon: <i className="icofont icofont-listing-number icofont-lg"></i>,
        //     designation: 'Tax In',
        //     place: personalDetails.tax_in
        // },

    ];


    const createToggleHandler = () => {
        setModal(!modal);
    };


    const handlePersonalAddress = async() =>{
        let data = await getDataWithToken(getPersonalInfo + `?user_id=${param1}`);
        setPersonalDetails(data.data)
    }

    const handleAddress = async () => {
        let data = await getDataWithToken(getAddress);
        setAddresslist(data.data.map((e, index) => {
            return {
                Si: index + 1,
                Name: <span>{e.name}</span>,
                CompanyName: e.company_name,
                Address: <span style={{ textAlign: 'center', display: 'block' }}>{e.address1} {e.address2}, <br />{e.city}, {e.state}, <br />{e.country}, {e.zip_code}</span>,
                action:
                    <div>
                        <span>
                            <Btn attrBtn={{ style: style2, color: 'primary btn-xs', type: 'button', onClick: () => redirectToEditForm(e) }}>Edit </Btn>
                        </span>
                        <span>
                            <Btn attrBtn={{ style: style2, className: 'me-2', color: 'danger btn-xs', type: 'button', onClick: () => { deleteUserAddressList(e.id) } }}>Delete</Btn>
                        </span>
                    </div >
            }
        }))
    };

    return (
        <Fragment>
            <CommonModalCancel isOpen={modal}
                title={EditProfile}
                toggler={() => {
                    setModal(!modal);
                }}
                size="lg">
                <EditProfile
                 personaldata = {personalDetails}
                //  handleModal  = {createToggleHandler()}
                 />
            </CommonModalCancel>
            <Card data-intro="This is your Your detail" className="step4">
                <CardHeader>
                    <div>
                        <div style={{ float: 'left' }}>
                            <H3 attrH3={{ className: 'p-0' }} >
                                <Btn attrBtn={{ color: 'transparent btn-link ps-0', onClick: () => { createToggleHandler() } }} >{AboutM}</Btn>
                            </H3>
                        </div>
                        <div style={{ float: 'right' }}>
                        <FaPencilAlt title="Customer Edit " onClick={() => createToggleHandler()} style={{ cursor: 'pointer', marginLeft: 'auto', fontSize: '0.8em' }} />
                        </div>
                        <div style={{ clear: 'both' }}></div>
                    </div>
                </CardHeader>
                <div className={`collapse ${isShow ? 'show' : ''}`}>
                    <CardBody className="post-about">
                        <UL attrUL={{ className: 'simple-list' }}>
                            <div className="d-flex">
                                <Image attrImage={{ className: 'img-60 img-fluid m-r-20 rounded-circle img-thumbnail', alt: '', src: `${require('../../../../assets/images/user/7.jpg')}` }} />
                                <div className="media-body flex-grow-1">
                                    <H5 className="d-block">{personalDetails.first_name} {personalDetails.last_name}</H5>
                                </div>
                            </div>
                            <div className="row"> {/* Add row class here */}
                                {AboutMeData.map((item) =>
                                    <div key={item.id} className="col-sm-6 col-md-4 col-xl-12" style={{marginBottom:'10px',marginTop:'10px'}}> {/* Wrap each LI in a col-md-4 */}
                                        <LI>
                                            <div className="icon">{item.icon}</div>
                                            <div>
                                                <H5>{item.designation}</H5>
                                                <P attrPara={{ className: 'text-dark' }}>{item.place}</P>
                                            </div>
                                        </LI>
                                    </div>
                                )}
                            </div>
                        </UL>
                    </CardBody>
                </div>
            </Card>
        </Fragment>
    );
};

export default AboutMe;
