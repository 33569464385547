// import { taxColumns, taxGroupColumns } from '../../../Data/Ecommerce/TaxList';
import React, { Fragment } from 'react';
import { Card, CardHeader, CardBody, Row, Modal, ModalHeader, ModalBody, Label, FormGroup, Form, Media, Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { useState, useEffect } from 'react';
import { deleteTax, deleteTaxGroup, postTaxData, statusTax, statusTaxGroup } from '../../../CommonURL';
import { Link, useNavigate } from 'react-router-dom';
import { Add, Delete, Tax, TaxRate } from '../../../Constant';
import { Btn, H5, H3, Breadcrumbs, H4 } from '../../../AbstractElements';
import { toast } from 'react-toastify';
import SweetAlert from 'sweetalert2';
import CommonModalCancel from '../../UiKits/Modals/common/modalcncl';
import SearchList from '../../../CommonElements/Search';
import { postDataWithToken } from '../../../Utils/restUtils';
import { taxColumns, taxGroupColumns } from '../../../Data/Ecommerce/TaxList';
import Transparentspinner from '../../Common/loader/Transparentspinner';

const Taxlist = () => {
    const [taxListData, setTaxListData] = useState([]);
    const [taxListModalData, setTaxListModalData] = useState([]);
    const [taxData, setTaxData] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [selectedTaxId, setSelectedTaxId] = useState(null);
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const navigate = useNavigate();
    const toggleStyle = { padding: '10px 20px', opacity: '0.8', fontSize: '14px' }


    const style2 = { width: 60, fontSize: 13, padding: 3 };

    useEffect(() => {
        getTaxGroupData();
        getTaxData(); // do not put await here
    }, [searchKeyword, selectedTaxId]);

    const taxGroupColumns = [
        {
            name: <H4>Sl</H4>,
            selector: (row) => row.Si,
            sortable: true,
            width: '50px'
        },
        {
            name: <i className="fa fa-gears" style={{ fontSize: '15px' }}></i>,
            selector: row => row.action,
            cell: row => <ActionCell row={row} />,
            width: '55px',
            center: true,

        },
        {
            name: <H4>Title</H4>,
            selector: (row) => row.Title,
            sortable: true,
            wrap: true,
            width: '110px',
        },
        {
            name: <H4>Tax</H4>,
            selector: (row) => row.tax,
            sortable: true,
        },
        {
            name: <H4>Code</H4>,
            selector: (row) => row.code,
            sortable: true,
        },
        {
            name: <H4>Description</H4>,
            selector: (row) => row.Description,
            sortable: true,
        },
        {
            name: <H4>Total %</H4>,
            selector: (row) => row.TotalPer,
            sortable: true,
        },
        {
            name: <H4>Status</H4>,
            selector: (row) => row.Status,
            sortable: true,
            wrap: true
        },
        // {
        //     name: <H4>Action</H4>,
        //     selector: (row) => row.action,
        //     sortable: true,
        // },
    ];


    const taxColumns = [
        {
            name: <H4>Sl</H4>,
            selector: (row) => row.Si,
            sortable: true,
            width: '50px'
        },
        // {
        //     name: <i className="fa fa-gears" style={{ fontSize: '15px' }}></i>,
        //     selector: row => row.action,
        //     cell: row => <ActionCell row={row} />,
        //     center: true,
        //     width: '55px',

        // },
        {
            name: <H4>Name</H4>,
            selector: (row) => row.Name,
            sortable: true,
            wrap: true
        },
        {
            name: <H4>Tax percentage</H4>,
            selector: (row) => row.TaxPer,
            sortable: true,
        },
        {
            name: <H4>Description</H4>,
            selector: (row) => row.Description,
            sortable: true,
            wrap: true
        },
        {
            name: <H4>Status</H4>,
            selector: (row) => row.Status,
            sortable: true,
        },
        {
            name: <H4>Action</H4>,
            selector: (row) => row.action,
            sortable: true,
            width: "200px"
        },
    ];


    const viewTaxClassRate = (id) => {
        setModal(!modal);
        setSelectedTaxId(id);
    };

    const filteredItems = taxData.filter(
        (item) => item.title && item.title.toLowerCase().includes(searchKeyword.toLowerCase()) ||
            item.description && item.description.toLowerCase().includes(searchKeyword.toLowerCase())
    );

    const updateSearchKeyword = (keyword) => {
        setSearchKeyword(keyword);
    };

    const redirectToTaxAddForm = (data) => {
        const taxAddFormURL = `${process.env.PUBLIC_URL}/configuration/tax-add?id=${data.id}`;
        navigate(taxAddFormURL);
    };

    const totaxClassEditForm = (data) => {
        const editFormURL = `${process.env.PUBLIC_URL}/configuration/tax-group-edit?id=${data.id}`;
        navigate(editFormURL);
    };

    const redirectToTaxEditForm = (data) => {
        const editFormURL = `${process.env.PUBLIC_URL}/configuration/tax-edit?id=${data.id}`;
        navigate(editFormURL);
    };

    const ActionCell = ({ row }) => {
        const [dropdownOpen, setDropdownOpen] = React.useState(false);
        const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

        return (
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle color="#00000" style={{ fontSize: '14px', zIndex: 1000 }}>
                    <i className="fa fa-ellipsis-v"></i>
                </DropdownToggle>
                <DropdownMenu container="body">
                    <DropdownItem style={toggleStyle} onClick={() => redirectToTaxAddForm(row)}>Add tax rate</DropdownItem>
                    <DropdownItem style={toggleStyle} onClick={() => viewTaxClassRate(row.id)}>View tax rate</DropdownItem>
                    <DropdownItem style={toggleStyle} onClick={() => totaxClassEditForm(row)}>Edit tax class</DropdownItem>
                    <DropdownItem style={{ padding: '10px 20px', color: 'red', opacity: '0.8', fontSize: '14px', margin: '0' }} onClick={() => deleteTaxId(row)}>Delete tax class</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        );
    };

    const handleSwitchToggle = async (event, itemId) => {
        const checked = event.target.checked;
        let reqSwitch = null;
        if (checked === true) {
            reqSwitch = { tax_group_id: itemId, status: 'inactive' }
        } else {
            reqSwitch = { tax_group_id: itemId, status: 'active' }
        }
        const statusChange = await postDataWithToken(statusTaxGroup, reqSwitch);
        if (statusChange.data.status === true) {
            toast.success(statusChange.data.message);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
    }

    const handleTaxSwitchToggle = async (event, itemId) => {
        const checked = event.target.checked;
        let reqSwitch = null;
        if (checked === true) {
            reqSwitch = { tax_id: itemId, status: 'inactive' }
        } else {
            reqSwitch = { tax_id: itemId, status: 'active' }
        }
        const statusChange = await postDataWithToken(statusTax, reqSwitch);
        if (statusChange.data.status === true) {
            toast.success(statusChange.data.message);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
    }

    const deleteTaxId = (data) => {
        SweetAlert.fire({
            title: 'Are you sure?',
            text: 'Once deleted, you will not be able to recover this imaginary file!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'cancel',
            reverseButtons: true
        }).then(async (result) => {
            if (result.value) {
                let deleteRes;
                if (data.delete_id === 1) {
                    deleteRes = { tax_group_id: data.id }
                } else {
                    deleteRes = { tax_id: data.id }
                }
                const packageRemove = await postDataWithToken(deleteTaxGroup, deleteRes);
                if (packageRemove.data.status === true) {
                    SweetAlert.fire(
                        'Deleted!',
                        'Your file has been deleted.',
                        'success'
                    );
                    toast.success(packageRemove.data.message);
                    if (data.delete_id === 1) {
                        getTaxGroupData();
                    } else {
                        getTaxData();
                    }

                } else {
                    toast.error(packageRemove.message);
                    // navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
                }
            } else {

            }
        });
    };

    // const deleteTaxList = (userId) => {
    //     SweetAlert.fire({
    //         title: 'Are you sure?',
    //         text: 'Once deleted, you will not be able to recover this imaginary file!',
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonText: 'Ok',
    //         cancelButtonText: 'cancel',
    //         reverseButtons: true
    //     }).then(async (result) => {
    //         if (result.value) {
    //             const deleteRes = { tax_id: userId }
    //             let packageRemove = await postDataWithToken(deleteTax, deleteRes);
    //             if (packageRemove.message === 'success') {
    //                 SweetAlert.fire(
    //                     'Deleted!',
    //                     'Your file has been deleted.',
    //                     'success'
    //                 );
    //                 getTaxData();
    //             } else {
    //                 navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
    //             }
    //         } else {

    //         }
    //     });
    // };
    const getTaxGroupData = async () => {
        setLoading(true);
        const data = await postDataWithToken(postTaxData);
        setResetPaginationToggle(!resetPaginationToggle);
        setTaxData(data.data.data)
        if (searchKeyword === '') {
            setTaxListData(data.data.data.map((e, index) => {
                return {
                    delete_id: 1,
                    Si: index + 1,
                    Title: e.title,
                    id: e.id,
                    // tax:
                    //     <div>
                    //         <span>
                    //             <Btn attrBtn={{ style: style2, className: 'me-2', color: 'primary btn-xs', type: 'button', onClick: () =>   (e) }}>Add Tax </Btn>
                    //         </span>
                    //         <span>
                    //             <Btn attrBtn={{ style: style2, color: 'primary btn-xs', type: 'button', onClick: () => { createToggleHandler(e.id) } }}>View Tax</Btn>
                    //         </span>
                    //     </div >,
                    // Code: e.code,
                    Description: e.description,
                    TotalPer: e.percentage,
                    Status:
                        <Media body className={`flex-grow-1 text-end icon-state`}>
                            <Label className="switch">
                                <Input type="checkbox"
                                    defaultChecked={e.status === "active" ? true : false}
                                    onChange={(event) => handleSwitchToggle(event, e.id)}
                                />
                                <span className="switch-state" ></span>
                            </Label>
                        </Media>,
                    // action:
                    //     <div>
                    //         <span>
                    //             <Btn attrBtn={{ style: style2, className: 'me-2', color: 'primary btn-xs', type: 'button', onClick: () => redirectToEditForm(e) }}>Edit </Btn>
                    //         </span>
                    //         <span>
                    //             <Btn attrBtn={{ style: style2, color: 'danger btn-xs', type: 'button', onClick: () => { deleteTaxGroupList(e.id) } }}>Delete</Btn>
                    //         </span>
                    //     </div >
                }
            }))
        } else {
            setTaxListData(filteredItems.map((e, index) => {
                return {
                    delete_id: 1,
                    Si: index + 1,
                    Title: e.title,
                    id: e.id,
                    // tax:
                    //     <div>
                    //         <span>
                    //             <Btn attrBtn={{ style: style2, className: 'me-2', color: 'primary btn-xs', type: 'button', onClick: () =>   (e) }}>Add Tax </Btn>
                    //         </span>
                    //         <span>
                    //             <Btn attrBtn={{ style: style2, color: 'primary btn-xs', type: 'button', onClick: () => { createToggleHandler(e.id) } }}>View Tax</Btn>
                    //         </span>
                    //     </div >,
                    // Code: e.code,
                    Description: e.description,
                    TotalPer: e.percentage,
                    Status:
                        <Media body className={`flex-grow-1 text-end icon-state`}>
                            <Label className="switch">
                                <Input type="checkbox"
                                    defaultChecked={e.status === "active" ? true : false}
                                    onChange={(event) => handleSwitchToggle(event, e.id)}
                                />
                                <span className="switch-state" ></span>
                            </Label>
                        </Media>,
                    // action:
                    //     <div>
                    //         <span>
                    //             <Btn attrBtn={{ style: style2, className: 'me-2', color: 'primary btn-xs', type: 'button', onClick: () => redirectToEditForm(e) }}>Edit </Btn>
                    //         </span>
                    //         <span>
                    //             <Btn attrBtn={{ style: style2, color: 'danger btn-xs', type: 'button', onClick: () => { deleteTaxGroupList(e.id) } }}>Delete</Btn>
                    //         </span>
                    //     </div >
                }
            }))
            setLoading(false);
        }
    };

    const getTaxData = async () => {
        setLoading(true);
        const taxId = { tax_group_id: selectedTaxId };
        const data = await postDataWithToken(postTaxData, taxId);
        setResetPaginationToggle(!resetPaginationToggle);
        if (searchKeyword === '') {
            data.data.data.map((item) => {
                setTaxListModalData(item.taxes.map((e, index) => {
                    return {
                        delete_id: 2,
                        Si: index + 1,
                        Name: e.name,
                        TaxPer: e.tax_percentage,
                        Description: e.description,
                        Status:
                            <Media body className={`flex-grow-1 text-end icon-state`}>
                                <Label className="switch">
                                    <Input type="checkbox"
                                        defaultChecked={e.status === "active" ? true : false}
                                        onChange={(event) => handleTaxSwitchToggle(event, e.id)}
                                    />
                                    <span className="switch-state" ></span>
                                </Label>
                            </Media>,
                        action:
                            <div>
                                <span>
                                    <Btn attrBtn={{ style: style2, className: 'me-2', color: 'primary btn-xs', type: 'button', onClick: () => redirectToTaxEditForm(e) }}>Edit </Btn>
                                </span>
                                <span>
                                    <Btn attrBtn={{ style: style2, color: 'danger btn-xs', type: 'button', onClick: () => { deleteTaxId(e) } }}>Delete</Btn>
                                </span>
                            </div >
                    }
                }))
            })
        }
        setLoading(false);
    };


    return (
        <Fragment>
            {loading && <Transparentspinner />}
            <Breadcrumbs mainTitle='Tax' parent="Configuration" title="Tax list" />
            <Card>
                <CardHeader><H3>Tax Class List</H3></CardHeader>
                <div style={{ paddingLeft: '22px', marginRight: '22px', marginBottom: '-10px' }}>
                    <div style={{ float: 'left' }}>
                        <SearchList updateSearchKeyword={updateSearchKeyword} />
                    </div>
                    <div style={{ float: 'right' }}>
                        <Link to={`${process.env.PUBLIC_URL}/configuration/tax-group-add`} className="btn btn-primary">{Add}</Link>
                    </div>
                    <div style={{ clear: 'both' }}></div>
                </div>
                <CardBody>
                    <CommonModalCancel isOpen={modal}
                        title={TaxRate}
                        toggler={() => {
                            setSelectedTaxId(null);
                            setModal(!modal);
                        }}
                        size="lg">
                        <div className="order-history table-responsive">
                            <DataTable
                                columns={taxColumns}
                                data={taxListModalData}
                                pagination
                            />
                        </div>
                    </CommonModalCancel>
                    <div className="order-history table-responsive">
                        <DataTable
                            columns={taxGroupColumns}
                            data={taxListData}
                            pagination
                        />
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    );
};

export default Taxlist;