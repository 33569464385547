import React, { Fragment, useEffect, useState } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row, InputGroup, Input } from 'reactstrap';
import { Btn, H3, Breadcrumbs } from "../../../AbstractElements";
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { addPaymentMethod, getStatus, getAccountSelect } from "../../../CommonURL";
import { getDataWithToken, postDataWithToken } from "../../../Utils/restUtils";
import SpinnerLoader from "../../../Layout/SpinnerLoader";
import DatePicker from 'react-datepicker';
import Typeahead from "../../../CommonElements/TypeAhead";
import { Name, Code, Type, Account, StartDate, EndDate, UsersPerCoupon, UsersPerCustomer, SubmitForm, AddPaymentMethod, Status, Description } from '../../../Constant';
// import '../../Style/AdminStyle.scss';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment from 'moment';
import Transparentspinner from "../../Common/loader/Transparentspinner";

const schema = yup.object().shape({
    name: yup.string().required().label(' Name'),
});


const PaymentForm = () => {
    const { register, handleSubmit, control, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(schema),
    });
    const [loading, setLoading] = useState(false);
    const [account, setAccount] = useState([]);
    const [accountOption, setAccountOption] = useState([]);
    const navigate = useNavigate();

    const typeOptions = [
        { value: "Online", label: "Online" },
        { value: "Offline", label: "Offline" },
    ];
    useEffect(() => {
        accountData();
    }, []);


    useEffect(() => {
        setAccountOption(
            account.map((item) => ({
                value: item.id,
                label: item.name,
            }))
        );
    }, [account]);
    const accountData = async () => {
        const data = await getDataWithToken(getAccountSelect);
        setAccount(data.data);
    };



    const onSubmit = async (data) => {
        setLoading(true);
        const postRequest = {
            name: data.name,
            type: data.type.value,
            account_id: data.account.value,

        }
        const addPackageResponse = await postDataWithToken(addPaymentMethod, postRequest);
        if (addPackageResponse.data.status === true) {
            navigate(`${process.env.PUBLIC_URL}/configuration/payment-method`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        setLoading(false);
    };


    return (
        <Fragment>
            {loading && (
                <Transparentspinner />
            )}

            <Breadcrumbs mainTitle='Payment Method' parent="Configuration" title="Payment Method" />
            <Card>
                <CardHeader className="pb-0">
                    <H3>{AddPaymentMethod}</H3>
                </CardHeader>
                <CardBody>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Row className="g-3">
                            <Col md='6'>
                                <Label className="form-label" for="name">{Name} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="name" type="text" {...register('name', { required: true })} />
                                <p className="text-danger">{errors.name?.message}</p>
                            </Col>

                            <Col md='6'>
                                <Label className="form-label" >{Type} <span className="requireStar">*</span></Label>
                                <Controller
                                    name="type"
                                    control={control}
                                    render={({ field }) => (
                                        <Typeahead
                                            options={typeOptions}
                                            onChange={(value) => {
                                                field.onChange(value);
                                            }}
                                            value={field.value}
                                        />
                                    )}
                                />
                                <p className="text-danger">{errors.type?.message}</p>
                            </Col>
                            <Col md="6">
                                <Label className="form-label" >{Account} <span className="requireStar">*</span></Label>
                                <Controller
                                    name="account"
                                    control={control} // Set the initial value to null
                                    render={({ field }) => (
                                        <Typeahead
                                            options={accountOption}
                                            onChange={(value) => {
                                                field.onChange(value); // Update the value in react-hook-form
                                            }}
                                            value={field.value}
                                        />
                                    )}
                                />
                                <p className="text-danger">{errors.account?.message}</p>

                            </Col>

                        </Row>
                        <FormGroup>
                        </FormGroup>
                        <Btn attrBtn={{ color: "primary" }} type="submit">{SubmitForm}</Btn>
                    </Form>
                </CardBody>
            </Card>
        </Fragment>
    )
}

export default PaymentForm;
