import React, { Fragment } from 'react';
import { Card, CardHeader, CardBody, Row, Modal, ModalHeader, ModalBody, Label, FormGroup, Form, Media, Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { useState, useEffect } from 'react';
import { deleteCoupon, getCoupon, getPackages, getStatus } from '../../../CommonURL';
import { Link, useNavigate } from 'react-router-dom';
import SweetAlert from 'sweetalert2';
import { Add } from '../../../Constant';
import { toast } from 'react-toastify';
import { Btn, H5, H3, Breadcrumbs, H4 } from '../../../AbstractElements';
import { getDataWithToken, postDataWithToken } from '../../../Utils/restUtils';
import SearchList from '../../../CommonElements/Search';
import { couponColumns } from '../../../Data/Ecommerce/CouponsList';
import Transparentspinner from '../../Common/loader/Transparentspinner';

const CouponList = () => {
    const [PackagesData, setPackagesData] = useState([]);
    const [packagesList, setPackagesList] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [loading, setLoading] = useState(false);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const navigate = useNavigate();
    const toggleStyle = { padding: '10px 20px', opacity: '0.8', fontSize: '14px' };

    const couponColumns = [
        {
          name: <H4>Sl</H4>,
          selector: (row) => row.Si,
          sortable: true,
          width:'50px'
        },
        {
            name: <i className="fa fa-gears" style={{ fontSize: '15px' }}></i>,
            selector: row => row.action,
            cell: row => <ActionCell row={row} />,
            width: '55px',
             center: true,
        },
        {
          name: <H4> Coupon Name</H4>,
          selector: (row) => row.Name,
          sortable: true,
          wrap: true,
          width:'120px'

        },
        {
          name: <H4>Code</H4>,
          selector: (row) =><span className='badge badge-active h6'>{row.Code}</span> ,
          sortable: true,
          wrap: true,
          width:'140px'
        },
        {
          name: <H4>Type</H4>,
          selector: (row) => row.Type,
          sortable: true,
          wrap: true,
          width:'120px'
        },
        {
          name: <H4>Discount</H4>,
          selector: (row) =><span className='billerq_color fw-bolder'>{row.Discount}</span> ,
          sortable: true,
          width:'100px'
        },
        {
          name: <H4>Start date</H4>,
          selector: (row) => row.Start_date,
          sortable: true,
          width:'100px',
          wrap: true
        },
        {
          name: <H4>End date</H4>,
          selector: (row) => row.End_date,
          sortable: true,
          width:'100px',
          wrap: true,
        },
        {
          name: <H4>Use per Coupon</H4>,
          selector: (row) => row.Users_per_coupon,
          sortable: true,
          width:'100px'
        },
        {
          name: <H4>Use per Customer</H4>,
          selector: (row) => row.Users_per_customer,
          sortable: true,
          width:'100px'
        },
        {
          name: <H4>Status</H4>,
          selector: (row) => row.Status,
          sortable: true,
          wrap: true,
          width:'100px'
        },
      ];


    useEffect(() => {
        getPackagesData(); 
    }, [searchKeyword]);

    const updateSearchKeyword = (keyword) => {
        setSearchKeyword(keyword);
    };

    const handleSwitchToggle = async (event, itemId) => {
        const checked = event.target.checked;
        let reqSwitch = null;
        if (checked === true) {
            reqSwitch = { coupon_id: itemId, status: 'inactive' }
        } else {
            reqSwitch = { coupon_id: itemId, status: 'active' }
        }
        const statusChange = await postDataWithToken(getStatus, reqSwitch);
        if (statusChange.data.status === true) {
            toast.success(statusChange.data.message);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
    }

    const deletePackagesList = (id) => {
        SweetAlert.fire({
            title: 'Are you sure?',
            text: 'Once deleted, you will not be able to recover this imaginary file!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'cancel',
            reverseButtons: true
        }).then(async (result) => {
            setLoading(true);
                if (result.value) {
                const deleteRes = { coupon_id: id }
                const packageRemove = await postDataWithToken(deleteCoupon, deleteRes);
                if (packageRemove.data.status === true) {
                    SweetAlert.fire(
                        'Deleted!',
                        'Your file has been deleted.',
                        'success'
                    );
                    toast.success(packageRemove.data.message);
                    getPackagesData();

                } else {
                    setLoading(false)
                    toast.error(packageRemove.message);
                }
            } else {

            }
            setLoading(false)
        });
    };
    const ActionCell = ({ row }) => {
        const [dropdownOpen, setDropdownOpen] = React.useState(false);
        const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

        return (
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle color="#00000" style={{ fontSize: '14px', zIndex: 1000 }}>
                    <i className="fa fa-ellipsis-v"></i>
                </DropdownToggle>
                <DropdownMenu container="body"> 
                    <DropdownItem style={toggleStyle} onClick={() => redirectToEditForm(row)}>Edit</DropdownItem>
                    <DropdownItem style={{padding: '10px 20px', color: 'red', opacity: '0.8', fontSize: '14px', margin: '0'}} onClick={() => deletePackagesList(row.id)}>Delete</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        );
    };

    const redirectToEditForm = (data) => {
        const editFormURL = `${process.env.PUBLIC_URL}/configuration/coupon-edit?id=${data.id}`;
        navigate(editFormURL);
    };

    const getPackagesData = async () => {
        setLoading(true);
        let data = await getDataWithToken(getCoupon);
        setResetPaginationToggle(!resetPaginationToggle);
        if (searchKeyword === '') {
            setPackagesData(data.data.map((e, index) => {
                return {
                    Si: index + 1,
                    id:e.id,
                    Name: e.name,
                    Code: e.code,
                    Type: e.type,
                    Discount: e.discount,
                    Start_date: e.start_date,
                    End_date: e.end_date,
                    Users_per_coupon: e.max_uses,
                    Users_per_customer: e.use_per_customer,
                    Status: <Media body className={`flex-grow-1 text-end icon-state`}>
                        <Label className="switch">
                            <Input type="checkbox"
                                defaultChecked={e.status === "active" ? true : false}
                                onChange={(event) => handleSwitchToggle(event, e.id)}
                            />
                            <span className="switch-state" ></span>
                        </Label>
                    </Media>,
                }
            }))
        }
        setLoading(false);
    };

    return (
        <Fragment>
            {loading && <Transparentspinner />}
            <Breadcrumbs mainTitle='Coupon' parent="Configuration" title="Coupon" />
            <Card>
                <CardHeader><H3>Coupon List</H3></CardHeader>
                <div style={{ paddingLeft: '22px', marginRight: '22px', marginBottom: '-10px' }}>
                    <div style={{ float: 'left' }}>
                        <SearchList updateSearchKeyword={updateSearchKeyword} />
                    </div>
                    <div style={{ float: 'right' }}>
                        <Link to={`${process.env.PUBLIC_URL}/configuration/coupon-add`} className="btn btn-primary">{Add}</Link>
                    </div>
                    <div style={{ clear: 'both' }}></div>
                </div>
                <CardBody>
                    <div className="order-history table-responsive">
                        <DataTable
                            pagination
                            columns={couponColumns}
                            data={PackagesData}
                        />
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    );
};

export default CouponList;