import React, { Fragment, useEffect, useState } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row } from 'reactstrap';
import { Btn, H3, Breadcrumbs } from "../../../AbstractElements";
import { useForm, Controller } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { getIndustryData, addTaxGroup, postTaxData, uploadTaxGroup } from "../../../CommonURL";
import { getDataWithToken, postDataWithToken } from "../../../Utils/restUtils";
import { EditTax, DiscriptionText, Name, NameError, Industry, LastNameError, SubmitForm, Code } from '../../../Constant';
import SpinnerLoader from "../../../Layout/SpinnerLoader";
import Typeahead from "../../../CommonElements/TypeAhead";
import Transparentspinner from "../../Common/loader/Transparentspinner";

const TaxGroupEdit = () => {
    const location = useLocation();
    const searchParams = location.search;
    const params = new URLSearchParams(searchParams);
    const param1 = params.get("id");
    const { register, handleSubmit, control, formState: { errors }, setValue } = useForm({});
    const [industry, setIndustry] = useState([]);
    const [industryOption, setIndustryOption] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        industryData(); // do not put await here
        getTaxData();
    }, []);

    useEffect(() => {
        setIndustryOption(industry.map((item) => ({
            value: item.id,
            label: item.name,
        })));
    }, [industry]);

    const industryData = async () => {
        let data = await getDataWithToken(getIndustryData);
        setIndustry(data.data);
    };

    const getTaxData = async () => {
        setLoading(true);
        const data = await postDataWithToken(postTaxData + `?tax_group_id=${param1}`);
        setValue('name', data.data.data[0].title);
        setValue('code', data.data.data[0].code);
        setValue('message', data.data.data[0].description);
        setValue('industry', { label: data.data.data[0].industry_name, value: data.data.data[0].industry_type_id });
        setLoading(false);
    }

    const onSubmit = async (data) => {
        setLoading(true);
        const postRequest = {
            tax_group_id: param1,
            title: data.name,
            code: data.code,
            industry_type_id: data.industry.value,
            description: data.message
        }
        const addTaxResponse = await postDataWithToken(uploadTaxGroup, postRequest);
        if (addTaxResponse.data.message === 'success') {
            navigate(`${process.env.PUBLIC_URL}/configuration/tax-list`);
        } else {
            setLoading(false);
            // errors.showMessages();
        }
        setLoading(false);
    };
    return (
        <Fragment>
            {loading && (
                <Transparentspinner />
            )}

            <Breadcrumbs mainTitle='Edit Tax Group' parent="Configuration" title="Tax Group" />
            <Card>
                <CardHeader className="pb-0">
                    <H3>{EditTax}</H3>
                </CardHeader>
                <CardBody>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Row className="g-3">
                            <Col md='6'>
                                <Label className="form-label" for="validationCustom01">{Name} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="validationCustom01" type="text" placeholder="Username" {...register('name', { required: true })} />
                                <span className="text-danger">{errors.firstname && NameError}</span>
                            </Col>
                            <Col md='6'>
                                <Label className="form-label" for="validationCustom01">{Code} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="validationCustom01" type="text" placeholder="" {...register('code', { required: true })} />
                                <span className="text-danger">{errors.code ? NameError : ''}</span>
                            </Col>
                            <Col md="6">
                                <Label className="form-label" for="validationCustom02">{Industry} <span className="requireStar">*</span></Label>
                                <Controller
                                    name="industry"
                                    control={control} // Set the initial value to null
                                    render={({ field }) => (
                                        <Typeahead
                                            options={industryOption}
                                            onChange={(value) => {
                                                field.onChange(value); // Update the value in react-hook-form
                                            }}
                                            value={field.value}
                                        />
                                    )}
                                />
                                <span className="text-danger">{errors.industry && IndustryError}</span>
                            </Col>
                        </Row>
                        <Row className="g-3">
                            <Col>
                                <Label className="form-label pt-3">{DiscriptionText}</Label>
                                <Controller
                                    name="message"
                                    control={control}
                                    render={({ field }) => (
                                        <textarea
                                            className="form-control"
                                            {...field}
                                            rows="3" // Set the 'rows' attribute to 3 here
                                        />
                                    )}
                                />
                            </Col>
                        </Row>
                        <FormGroup>
                        </FormGroup>
                        <Btn attrBtn={{ color: "primary" }} type="submit">{SubmitForm}</Btn>
                    </Form>
                </CardBody>
            </Card>
        </Fragment>
    )
}
export default TaxGroupEdit;