import React, { Fragment } from 'react';
import { Card, CardHeader, CardBody, Row, Modal, ModalHeader, ModalBody, Label, FormGroup, Form, Media, Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { useState, useEffect } from 'react';
import { deletePackage, getPackages, statusChangePackage } from '../../../CommonURL';
import { Link, useNavigate } from 'react-router-dom';
import SweetAlert from 'sweetalert2';
import { Add } from '../../../Constant';
import { toast } from 'react-toastify';
import { Btn, H5, H3, Breadcrumbs, H4 } from '../../../AbstractElements';
import { getDataWithToken, postDataWithToken } from '../../../Utils/restUtils';
import SearchList from '../../../CommonElements/Search';
import { packageColumns } from '../../../Data/Ecommerce/PackagesList';
import Transparentspinner from '../../Common/loader/Transparentspinner';

const HoverableRowsClass = () => {
    const [PackagesData, setPackagesData] = useState([]);
    const [packagesList, setPackagesList] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const style2 = { width: 60, fontSize: 13, padding: 3 };
    const toggleStyle = { padding: '10px 20px', opacity: '0.8', fontSize: '14px' }

    useEffect(() => {
        getPackagesData(); 
    }, [searchKeyword]);

     const packageColumns = [
        {
          name: <H4>Sl</H4>,
          selector: (row) => row.Si,
          sortable: true,
          width:'50px'
        },
        {
            name: <i className="fa fa-gears" style={{ fontSize: '15px' }}></i>,
            selector: row => row.action,
            cell: row => <ActionCell row={row} />,
            width: '55px',
             center: true,
        },
        {
          name: <H4>Name</H4>,
          selector: (row) => row.Name,
          sortable: true,
          wrap: true,
          width:'100px'
        },
        {
          name: <H4>Price</H4>,
          selector: (row) => "₹"+" " + row.Price,
          sortable: true,
          width:'100px'
        },
        {
          name: <H4>Mrp</H4>,
          selector: (row) =>"₹"+" " + row.Mrp,
          sortable: true,
          width:'100px'
        },
        {
          name: <H4>Tax Group</H4>,
          selector: (row) => row.TaxGroup,
          sortable: true,
          width:'100px'
        },
        {
          name: <H4>Industry</H4>,
          selector: (row) => row.Industry,
          sortable: true,
          width:'100px'
        },
        {
          name: <H4>Setup Cost</H4>,
          selector: (row) => "₹"+" " +row.SetupCost,
          sortable: true,
          width:'100px'
        },
        {
          name: <H4>Expiry Duration</H4>,
          selector: (row) => row.ExpiaryDate,
          sortable: true,
          width:'100px'
        },
        {
          name: <H4>Features</H4>,
          selector: (row) => row.Features,
          sortable: true,
          width:'190px',
          wrap:true
        },
        {
          name: <H4>Status</H4>,
          selector: (row) => row.Status,
          sortable: true,
          wrap: true
        },  
        // {
        //   name: <H4>Action</H4>,
        //   selector: (row) => row.action,
        //   sortable: true,
        // },
      ];

    const filteredItems = packagesList.filter(
        (item) => item.name && item.name.toLowerCase().includes(searchKeyword.toLowerCase()) ||
            item.description && item.description.toLowerCase().includes(searchKeyword.toLowerCase()) ||
            item.tax && item.tax.toLowerCase().includes(searchKeyword.toLowerCase()) ||
            item.industry_name && item.industry_name.toLowerCase().includes(searchKeyword.toLowerCase()) ||
            item.features && item.features.toLowerCase().includes(searchKeyword.toLowerCase())
    );

    const updateSearchKeyword = (keyword) => {
        setSearchKeyword(keyword);
    };

    const handleSwitchToggle = async (event, itemId) => {
        const checked = event.target.checked;
        let reqSwitch = null;
        if (checked === true) {
            reqSwitch = { package_id: itemId, status: 'inactive' }
        } else {
            reqSwitch = { package_id: itemId, status: 'active' }
        }
        const statusChange = await postDataWithToken(statusChangePackage, reqSwitch);
        if (statusChange.data.status === true) {
            toast.success(statusChange.data.message);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
    }

    const deletePackagesList = (userId) => {
        
        SweetAlert.fire({
            title: 'Are you sure?',
            text: 'Once deleted, you will not be able to recover this imaginary file!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'cancel',
            reverseButtons: true
        }).then(async (result) => {
            setLoading(true)
            if (result.value) {
                const deleteRes = { package_id: userId }
                const packageRemove = await postDataWithToken(deletePackage, deleteRes);
                if (packageRemove.data.message === 'success') {
                    SweetAlert.fire(
                        'Deleted!',
                        'Your file has been deleted.',
                        'success'
                    );
                    getPackagesData();
                    setLoading(false)
                } else {
                    navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
                }
            } else {
                setLoading(true)
            }
        });
    };

    const redirectToEditForm = (data) => {
        const editFormURL = `${process.env.PUBLIC_URL}/configuration/packages-edit?id=${data.id}`;
        navigate(editFormURL);
    };

    const ActionCell = ({ row }) => {
        const [dropdownOpen, setDropdownOpen] = React.useState(false);
        const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

        return (
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle color="#00000" style={{ fontSize: '14px', zIndex: 1000 }}>
                    <i className="fa fa-ellipsis-v"></i>
                </DropdownToggle>
                <DropdownMenu container="body"> 
                    <DropdownItem style={toggleStyle} onClick={() => redirectToEditForm(row)}>Edit</DropdownItem>
                    <DropdownItem style={{padding: '10px 20px', color: 'red', opacity: '0.8', fontSize: '14px', margin: '0'}} onClick={() => deletePackagesList(row.id)}>Delete</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        );
    };

    const getPackagesData = async () => {
        setLoading(true)
        let data = await getDataWithToken(getPackages);
        setResetPaginationToggle(!resetPaginationToggle);
        setPackagesList(data.data)
        if (searchKeyword === '') {
            setPackagesData(data.data.map((e, index) => {
                return {
                    Si: index + 1,
                    id:e.id,
                    Name: e.name,
                    Description: e.description,
                    Features: e.features,
                    Price: e.price,
                    Mrp: e.mrp,
                    TaxGroup: e.tax_group_name,
                    Industry: e.industry_name,
                    SetupCost: e.setup_cost ,
                    ExpiaryDate: e.duration,
                    Status: 
                    <Media body className={`flex-grow-1 text-end icon-state`}>
                        <Label className="switch">
                            <Input type="checkbox"
                                defaultChecked={e.status === "active" ? true : false}
                                onChange={(event) => handleSwitchToggle(event, e.id)}
                            />
                            <span className="switch-state" ></span>
                        </Label>
                    </Media>,
                    action:
                        <div>
                            <span>
                                <Btn attrBtn={{ style: style2, className: 'me-2', color: 'primary btn-xs', type: 'button', onClick: () => redirectToEditForm(e) }}>Edit </Btn>
                            </span>
                            <span>
                                <Btn attrBtn={{ style: style2, color: 'danger btn-xs', type: 'button', onClick: () => { deletePackagesList(e.id) } }}>Delete</Btn>
                            </span>
                        </div >
                }
            }))
        }
        setLoading(false)
    };
    return (
        <Fragment>
            {loading && <Transparentspinner />}
            <Breadcrumbs mainTitle='Package' parent="Configuration" title="Package" />
            <Card>
                <CardHeader><H3>Package List</H3></CardHeader>
                <div style={{ paddingLeft: '22px', marginRight: '22px', marginBottom: '-10px' }}>
                    <div style={{ float: 'left' }}>
                        <SearchList updateSearchKeyword={updateSearchKeyword} />
                    </div>
                    <div style={{ float: 'right' }}>
                        <Link to={`${process.env.PUBLIC_URL}/configuration/packages-add`} className="btn btn-primary">{Add}</Link>
                    </div>
                    <div style={{ clear: 'both' }}></div>
                </div>
                <CardBody>
                    <div className="order-history table-responsive">
                        <DataTable
                            pagination
                            columns={packageColumns}
                            data={PackagesData}
                        />
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    );
};

export default HoverableRowsClass;