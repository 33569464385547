import React, { useState, useEffect, useMemo, Fragment } from "react";
import { Col, Card, CardHeader, CardBody } from 'reactstrap';
import { useForm } from 'react-hook-form';
import DataTable from 'react-data-table-component';
import { H3, Btn, Breadcrumbs } from '../../../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';
import { postShowTemp } from '../../../CommonURL';
import { getDataWithToken } from '../../../Utils/restUtils';
import { Add } from '../../../Constant';
import { TempDataColumns } from '../../../Data/MockTable/TempData';
import SearchList from '../../../CommonElements/Search';
import Transparentspinner from "../../Common/loader/Transparentspinner";

const TempTable = () => {
    const style2 = { width: 60, fontSize: 13, padding: 3, marginRight: '10px' };
    const [tempList, setTempList] = useState([]);
    const [tempDataValue, setTempDataValue] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    useEffect(() => {
        fetchUsers(1);
    }, [searchKeyword]);


    const filteredItems = tempDataValue.filter(
        (item) => item.first_name && item.first_name.toLowerCase().includes(searchKeyword.toLowerCase()) ||
            item.industry_name && item.industry_name.toLowerCase().includes(searchKeyword.toLowerCase()) ||
            item.last_name && item.last_name.toLowerCase().includes(searchKeyword.toLowerCase()) ||
            item.state && item.state.toLowerCase().includes(searchKeyword.toLowerCase())
    );

    const updateSearchKeyword = (keyword) => {
        setSearchKeyword(keyword);
    };

    const fetchUsers = async (page, size = perPage) => {
        setLoading(true);
        const response = await getDataWithToken(postShowTemp + `?page=${page}&page_length=${size}&search=&industry_id=`);
        setResetPaginationToggle(!resetPaginationToggle);
        setTempDataValue(response.data.data);
        let serialNo = (page - 1) * size + 1;
        if (searchKeyword === '') {
            setTempList(response.data.data.map((e, index) => {
                return {
                    Si: serialNo + index ,
                    Name: <span>{e.first_name} {e.last_name}</span>,
                    Email: e.email,
                    Mobile_Number: e.mobile,
                    Industry: e.industry_name,
                    State: e.state,
                    Country: e.country,
                }
            }))
            setLoading(false);
        } else {
            setTempList(filteredItems.map((e, index) => {
                return {
                    Si: index + 1,
                    Name: <span>{e.first_name} {e.last_name}</span>,
                    Email: e.email,
                    Mobile_Number: e.mobile,
                    Industry: e.industry_name,
                    State: e.state,
                    Country: e.country,
                }
            }))
        }
        setTotalRows(response.data.total);
        setLoading(false);
    };


    const handlePageChange = page => {
        fetchUsers(page);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        fetchUsers(page, newPerPage);
        setPerPage(newPerPage);
    };

    return (
        <Fragment>
            {loading && <Transparentspinner />}
            <Breadcrumbs mainTitle='Temporary Subscribers' parent="Subscribers" title="Temporary" />
            <Card>
                <CardHeader>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <H3>Temporary Subscribers List</H3>
                        <SearchList updateSearchKeyword={updateSearchKeyword} />
                    </div>
                </CardHeader>
                <CardBody>
                    <div className="order-history table-responsive">
                        <DataTable
                            columns={TempDataColumns}
                            data={tempList}
                            pagination
                            paginationServer
                            defaultSortAsc
                            paginationTotalRows={totalRows}
                            paginationDefaultPage={currentPage}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            // selectableRows
                            onSelectedRowsChange={({ selectedRows }) => console.log(selectedRows)}
                        />
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    );


};

export default TempTable;