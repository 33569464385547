import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Btn } from '../../../../AbstractElements';
import { AddPayments, Cancel, Paid, PaymentDetail, Print, ThermalPrint, share } from '../../../../Constant';
import ItemDescription from './ItemDescription';
import { Col } from 'reactstrap';
import { useReactToPrint } from 'react-to-print';
import { useLocation, useNavigate } from 'react-router';
import Transparentspinner from '../../../Common/loader/Transparentspinner';
import { getGeneratePayment, getInvoiceDetail, getMakePayment, getShareApi, postMakePayment } from '../../../../CommonURL';
import { getDataWithToken, postDataWithToken, postMasterToken } from '../../../../Utils/restUtils';
import { toast } from 'react-toastify';
import Popup from '../../../Common/Popup/Popup';
import PaymentFormPopUp from '../../../Billing/invoice/PaymentForm';
import SweetAlert from 'sweetalert2';

const PrintComponent = () => {
    const location = useLocation();
    const searchParams = location.search;
    const params = new URLSearchParams(searchParams);
    const userId = params.get("order_id");
    const navigate = useNavigate();
    const componentRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [invoiceDetail, setInvoiceDetail] = useState({});
    const [statusColor, setStatusColor] = useState('');

    useEffect(() => {
        getInvoiceData();
    }, [])

    const getInvoiceData = async () => {
        setLoading(true);
        const data = await getDataWithToken(getInvoiceDetail + `?order_id=${userId}`);
        setInvoiceDetail(data.data);
        if (data.data.payment_status === "pending") {
            setStatusColor('#dc0808');
        } else if (data.data.payment_status === 'paid') {
            setStatusColor('#67c100');
        } else if (data.data.payment_status === 'paschal') {
            setStatusColor('#e56809');
        } else {
            setStatusColor('#534686');
        }
        setLoading(false);
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const redirectToPayment = async () => {
        setLoading(true);
        setModal(!modal);
        setLoading(false);
    };

    const MakePayment = () => {
        SweetAlert.fire({
            title: 'Are you sure?',
            text: 'Do you want to make payment?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'cancel',
            reverseButtons: true
        }).then(async (result) => {
            setLoading(true);
            if (result.value) {
                const paymentRes = { order_id: userId, }
                const payment = await postDataWithToken(postMakePayment, paymentRes);
                if (payment.data.message === "payment successful") {
                    SweetAlert.fire(
                        'Paid!',
                        'Account is Paid.',
                        'success'
                    );
                    getInvoiceData();
                } else {
                    navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
                    setLoading(false);
                }
            }
            setLoading(false);
        });
    };

    const shareApi = async () => {
        const responce = await getDataWithToken(getShareApi + `?order_id=${userId}`);
        if (responce.status === true) {
            navigate(`/invoice?order_id=${responce.data}`);
        }
    };


    return (
        <Fragment>
            {loading && <Transparentspinner />}
            <ItemDescription
                invoiceDetail={invoiceDetail}
                statusColor={statusColor}
                setLoading={setLoading}
            />
            <Col sm="12" className="text-center my-3">
                <Btn attrBtn={{ color: 'primary', className: 'me-2', onClick: handlePrint }}>
                    {Print}
                </Btn>
                <Btn attrBtn={{ color: 'secondary', className: 'me-2', onClick: () => shareApi() }}>
                    {share}
                </Btn>
                {invoiceDetail.payment_status !== "paid" &&
                    <Btn attrBtn={{ color: 'secondary', className: 'me-2', onClick: () => MakePayment() }}>
                        {AddPayments}
                    </Btn>
                }
            </Col>
            <Popup isOpen={modal} title={PaymentDetail} toggler={redirectToPayment}>
                <PaymentFormPopUp orderId={userId} setModal={setModal} />
            </Popup>
        </Fragment>
    );
};

export default PrintComponent;