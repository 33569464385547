import React, { Fragment, useState, useEffect } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row, Input, Media, } from "reactstrap";
import { Btn, H3, Breadcrumbs, H6, H5 } from "../../../../AbstractElements";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import SpinnerLoader from "../../../../Layout/SpinnerLoader";
import { useSelector } from "react-redux";
// import { getClientUrlDataToken, postClientUrlWithToken, } from "../../../../Utils/restUtils";
import { toast } from "react-toastify";
import Typeahead from "../../../../CommonElements/TypeAhead";
import {
  Amount, PaymentMethod, DateText, Description, Save, Reference, InvoicePayment, InvoicePaymentAdd, Customer, Total, totalPending, Invoice
} from "../../../../Constant";
import DatePicker from 'react-datepicker';
import { format } from "date-fns";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Select from 'react-select';
import moment from "moment";
// import Transparentspinner from "../../../Common/Loader/Transparentspinner";
import SweetAlert from 'sweetalert2';



const schema = yup.object().shape({
  customer: yup.mixed().required().label('Customer'),
  amount: yup.string().required().label('Amount'),
  date: yup.string().required().label('Date'),
});

const PaymentForm = () => {
  const [spinnerTrans, setspinnerTrans] = useState(true)

  const { register, handleSubmit, control, formState: { errors }, setValue, getValues } = useForm({
    defaultValues: {
      date: format(new Date(), "yyyy-MM-dd"),
    },
    resolver: yupResolver(schema),
  });

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      height: '46px', // Set your desired height here
      minHeight: '46px', // Ensure the minimum height is also set
      borderColor: state.isFocused ? '#534686' : '#d2d2d2', // Change border color on focus
    boxShadow: state.isFocused ? '0 0 0 1px #534686' : provided.boxShadow, // Ensure box shadow matches border color
    '&:hover': {
      borderColor: state.isFocused ? '#534686' : '#d2d2d2' // Change border color on hover when focused
    },
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: '46px', // Match the height here as well
      display: 'flex',
      alignItems: 'center',
    }),
    input: (provided) => ({
      ...provided,
      height: '46px', // Ensure the input area height matches
      padding: '0',
      margin: '0',
    }),
  };

  const authState = useSelector((state) => state.auth);
  const location = useLocation();
  const searchParams = location.search;
  const params = new URLSearchParams(searchParams);
  const param1 = params.get("customer_id");
  const param2 = params.get("invoice_id");
  const param3 = params.get("invoice_no");
  const param4 = params.get("con_type");
  const [loading, setLoading] = useState(false);
  const [Payment, setPayment] = useState([]);
  const [startDate, setstartDate] = useState(new Date());
  const [customerId, setCustomerId] = useState(param1);
  const [invoiceOptions, setinvoceOptions] = useState([]);
  const [initialRender, setInitialRender] = useState(true);
  const [isChecked, setisChecked] = useState(false);
  const [isAmount, setisAmount] = useState('');
  const [paymentMethodError, setPaymentMethodError] = useState("");
  const navigate = useNavigate();

  const type = [
    { value: '', label: "Any" },
    { value: "cable", label: "Cable" },
    { value: "broadband", label: "Broadband" },
    { value: "ip_tv", label: "IPTV" },
    { value: "ott", label: "OTT" },
    { value: 'sales_order', label: 'Sales Order' },
    { value: "others", label: "Others" },
  ];


  useEffect(() => {
    // paymentData();
    // setCustomer();
    // selectType(param4);
  }, []);

  useEffect(() => {
    const inputElement = document.querySelectorAll('input[type="number"]');

    const preventScrollEvent = (event) => {
      event.preventDefault();
    };

    inputElement.forEach((inputElement) => {
      inputElement.addEventListener('wheel', preventScrollEvent);

      return () => {
        inputElement.removeEventListener('wheel', preventScrollEvent);
      };
    });
  }, []);

  useEffect(() => {
    if (initialRender) { // Only on initial render
      const findContype = type.find((e) => e.value == param4);
      setValue("type", findContype);
      setInitialRender(false); // Update initial render flag
    }
  }, [param4]);


  const handleChangeDate = date => {
    setstartDate(date);
  };


  const paymentData = async () => {
    let payment = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? paymentMethod : paymentMethodRole);
    if (payment.status === true) {
      const set_payment = payment.data.map((itemss) => ({
        value: itemss.id,
        label: itemss.name,
      }));
      setPayment(set_payment);
    } else if (payment.status >= 400 && payment.status <= 405) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
    } else if (payment.status >= 500 && payment.status <= 505) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
    } else {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
    }
  };

  const setCustomer = async () => {
    let cust = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getCustomers : getCustomersRole);
    if (cust.status === true) {
      const set_Customers = cust.data.map((itemss) => ({
        value: itemss.id,
        label: itemss.name,
      }));
      const defaultcustomer = set_Customers?.find(
        (e) => e.value === Number(param1)
      );
      setValue("customer", defaultcustomer?.label);
    } else if (cust.status >= 400 && cust.status <= 405) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
    } else if (cust.status >= 500 && cust.status <= 505) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
    } else {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
    }
  };

  const selectAmountType = async (typeValue, stbId, invoiceValue) => {
    setspinnerTrans(true);

    if (customerId) {
      const customer_id = Number(customerId);
      const selected_con = typeValue ? typeValue : '';
      const selected_stb = stbId ? stbId : '';
      let type_get_api;
      if (selected_con === 'sales_order') {
        type_get_api = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? paymentSelectbox + `?customer_id=${customer_id}&connection_type=${selected_con}`
          + (invoiceValue !== undefined ? invoiceValue.map(id => `&invoice_no[]=${id}`).join('') : ``) :
          paymentSelectboxRole + `?customer_id=${customer_id}&connection_type=${selected_con}`
          + (invoiceValue !== undefined ? invoiceValue.map(id => `&invoice_no[]=${id}`).join('') : ``));
      } else {
        type_get_api = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? paymentSelectbox + `?customer_id=${customer_id}&connection_type=${selected_con}`
          + (selected_stb !== '' ? `&stb_id[]=${selected_stb}` : `&stb_id=${selected_stb}`) +
          (invoiceValue !== undefined ? invoiceValue.map(id => `&invoice_no[]=${id}`).join('') : ``) :
          paymentSelectboxRole + `?customer_id=${customer_id}&connection_type=${selected_con}`
          + (selected_stb !== '' ? `&stb_id[]=${selected_stb}` : `&stb_id=${selected_stb}`) +
          (invoiceValue !== undefined ? invoiceValue.map(id => `&invoice_no[]=${id}`).join('') : ``));
      }
      if (type_get_api.status === true) {
        const single_stb = type_get_api.data;
        const pending_amount = single_stb.total_pending_amount.replace(/,/g, '');
        const total_payable = single_stb.total_payable.replace(/,/g, '');

        setValue("pending", pending_amount || 0);
        setValue("total", total_payable || 0);
        setValue("amount", total_payable || 0);
        setisAmount(total_payable);

      }
      else if (type_get_api.status >= 400 && type_get_api.status <= 405) {
        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
      } else if (type_get_api.status >= 500 && data.status <= 505) {
        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
      }
      else {
        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
      }
    }
    setspinnerTrans(false);

  }

  const handleSwitchToggle = async (data, subId) => {
    // event.preventDefault();
    const getAmount = getValues("amount")
    SweetAlert.fire({
      title: `<b>Amount </b>:  ${getAmount} `,
      text: 'Note : Payment is irreversible once processed.No changes allowed.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
      reverseButtons: true
    }).then(async (result) => {
      if (!result.value) {
        return;
      }

      if (!isChecked && !data.paymentMethod) {
        setPaymentMethodError("Payment method is required"); // Setting error message for payment method
        return;
      }
      setLoading(true);
      if (isChecked || data.paymentMethod) {
        const totalPayable = parseFloat(data.amount.replace(/,/g, ''));
        const isCheckedwallet = getValues("wallet_money")
        const postRequest = {
          customer_id: param1,
          connection_type: data.type.value,
          amount: totalPayable,
          date: moment(startDate),
          payment_method: isChecked ? "" : data.paymentMethod?.value,
          reference: data.reference,
          description: data.description ?? '',
          ...(data.stb && { stb: data.stb.value }),
          ...(data.invoice && { order_id: [data.invoice.value] }),
          wallet_money: isCheckedwallet ? isCheckedwallet : ''
        };

        const addAddonResponse = await postClientUrlWithToken(
          authState.apiUrl, authState.userRole === 1 ? addInvoicePayment : addInvoicePaymentRole, postRequest
        );
        if (addAddonResponse.status === 200) {
          if (addAddonResponse.data.status === true) {
            toast.success(addAddonResponse.data.message);

            const paymentID = addAddonResponse.data.data;
            navigate(`${process.env.PUBLIC_URL}/billing/thermal-print?payment_id=${paymentID}`);
          } else if (Array.isArray(addAddonResponse.data?.message)) {
            const error = addAddonResponse.data?.map((e) => e.message)
            toast.error(error);
            setLoading(false);

          } else {
            toast.error(addAddonResponse.data.message);
            setLoading(false);

          }
        } else if (addAddonResponse.status >= 400 && addAddonResponse.status <= 405) {
          navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (addAddonResponse.status >= 500 && addAddonResponse.status <= 505) {
          navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
          navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
      }
      setLoading(false);


    })
  };


  return (
    <Fragment>
      {loading && <SpinnerLoader />}
      {/* {spinnerTrans && <Transparentspinner />} */}


      <Breadcrumbs mainTitle={InvoicePayment} parent="Billing" title={InvoicePayment} />
      <Form className="needs-validation" noValidate onSubmit={handleSubmit(handleSwitchToggle)} >
        <Card>
          <CardHeader className="pb-0">
            <H3>{InvoicePaymentAdd}</H3>
          </CardHeader>
          <CardBody>
            <Row className="g-3 pt-2">
              <Col md="6">
                <Label className="form-label" for="validationCustom02">
                  {Customer} <span className="requireStar">*</span>
                </Label>
                <input
                  className="form-control"
                  id="validationCustom02"
                  type="text"
                  {...register("customer", { required: true })}
                  readOnly
                />
                <p className="text-danger">{errors.name?.message}</p>
              </Col>
              <Col md="6" className="mt-3">
                <Label className="form-label" for="multiple-typeahead">{Invoice}</Label>
                <Controller
                  name="invoice"
                  control={control}
                  render={({ field }) => (
                    <Select
                      closeMenuOnSelect={true}
                      isMulti
                      styles={customStyles}
                      onChange={(value) => {
                        field.onChange(value)
                        const selectedInvoiceValue = value ? value.map(option => option.value) : undefined; // Extracting value from selected array
                        const typeValue = getValues('type') ? getValues('type').value : ''; // Get the connection type value
                        const stbValue = getValues('stb') ? getValues('stb').value : ''; // Get the STB value
                        selectAmountType(typeValue, stbValue, selectedInvoiceValue);
                      }
                      }
                      value={field.value}
                      options={invoiceOptions}
                    />
                  )}
                />
              </Col>
            </Row>
            <Row className="g-3 pt-3">
              <Col md="6">
                <Label className="form-label" for="validationCustom02">{totalPending}</Label>
                <input
                  className="form-control"
                  id="validationCustom02"
                  disabled
                  type="number"
                  {...register("pending", { required: true })}
                />
              </Col>
              <Col md="6">
                <Label className="form-label" for="validationCustom02">
                  {Total}
                </Label>
                <input
                  className="form-control"
                  id="validationCustom02"
                  disabled
                  type="number"
                  {...register("total", { required: true })}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <Row className="g-3 pt-3">
              <Col md="6">
                <Label className="form-label" for="validationCustom02">
                  {Amount}<span className="requireStar">*</span>
                </Label>
                <Controller
                  name="amount"
                  control={control}
                  render={({ field }) => (
                    <input
                      className="form-control"
                      id="amount"
                      type="number"
                      min="0"
                      onChange={(value) => {
                        field.onChange(value);
                        const e = value.target.value;
                        setisAmount(e)
                      }}
                      value={field.value}
                    />
                  )}
                />
                <p className="text-danger">{errors.amount?.message}</p>
              </Col>
              {isChecked === false &&
                <Col md="6">
                  <Label className="form-label" for="validationCustom02">
                    {PaymentMethod}<span className="requireStar">*</span>
                  </Label>
                  <Controller
                    name="paymentMethod"
                    control={control}
                    render={({ field }) => (
                      <Typeahead
                        options={Payment}
                        onChange={(value) => {
                          field.onChange(value);
                          setPaymentMethodError("");
                        }}
                        value={field.value}
                      />
                    )}
                  />
                  {paymentMethodError && <p className="text-danger">{paymentMethodError}</p>} {/* Display error message */}
                  {/* <p className="text-danger">{errors.paymentMethod?.message}</p> */}
                </Col>
              }
              <Col md="6">
                <FormGroup className="mb-3">
                  <Label className="form-label" for="validationCustom02">{DateText}</Label>
                  <Col xl='12' md="12" sm='12'>
                    <DatePicker
                      style={{ padding: '12px 100px' }}
                      className="form-control "
                      dateFormat="dd/MM/yyyy"
                      placeholderText="dd/mm/yyyy"
                      selected={startDate}
                      onChange={handleChangeDate}
                      required
                    />
                  </Col>
                </FormGroup>
              </Col>
              <Col md="6">
                <Label className="form-label" for="validationCustom02">{Reference}</Label>
                <input
                  className="form-control"
                  id="validationCustom02"
                  type="text"
                  {...register("reference", { required: true })}
                />
                <p className="text-danger">{errors.name?.message}</p>
              </Col>
            </Row>
            <Row className="g-3">
              <Col>
                <Label className="form-label pt-3">{Description}</Label>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <textarea className="form-control" {...field} rows="3" />
                  )}
                />
              </Col>
            </Row>
            <FormGroup></FormGroup>
            <Btn attrBtn={{ color: "primary" }} type="submit">
              {Save}
            </Btn>
          </CardBody>
        </Card>
      </Form>
    </Fragment>
  );
};
export default PaymentForm;
