import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { Btn, H3, P } from '../../../AbstractElements';
import { Invoice, Payment } from '../../../Constant';
import DataTable from 'react-data-table-component';
import { getDataWithToken } from '../../../Utils/restUtils';
import { getInvoiceList, getPayment, getPaymentList, getShowOrder } from '../../../CommonURL';
import { InvoiceDataColumns } from '../../../Data/MockTable/InvoiceData';
import { PaymentColumns } from '../../../Data/MockTable/TableHeaderList';
import { useNavigate } from 'react-router';

const BottomTableTabs = (props) => {
    const propsValue = props.params;
    const style2 = { fontSize: 13, padding: 3, marginRight: '10px' };
    const [PrimarycolorLineTab, setPrimarycolorLineTab] = useState('1');
    const [invoiceList, setInvoiceList] = useState([]);
    const [paymentList, setPaymentList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();

    useEffect(() => {
        invoiceData(1); // do not put await here
        PaymentData();
    }, []);

    const handlePageChange = page => {
        invoiceData(page);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        invoiceData(page, newPerPage);
        setPerPage(newPerPage);
    };

    const redirectToEditForm = (data) => {
        const editFormURL = `${process.env.PUBLIC_URL}/companies/company-list/userProfile/invoice?id=${data.id}&user_id=${propsValue}`;
        navigate(editFormURL);
    };

    const invoiceData = async (page, size = perPage) => {
        let response = await getDataWithToken(getInvoiceList + `?user_id=${propsValue}`);
        setInvoiceList(response.data.map((e, index) => {
            return {
                Si: index + 1,
                Invoice_no: <span>{e.invoice_number}</span>,
                Invoiced_at: e.start_date,
                Billing_at: e.billing_date,
                Subscription: e.subscription_name,
                Amount_due: e.amount_due,
                Tax: e.tax,
                Total: e.total,
                Unit_price: e.unit_price,
                Status: <span className='font-success'>{e.payment_status}</span>,
                action:
                    <div>
                        <span>
                            <Btn attrBtn={{ style: style2, color: 'primary btn-xs', type: 'button', onClick: () => redirectToEditForm(e) }}>View More</Btn>
                        </span>
                    </div >
            }
        }))
        setTotalRows(response.data.total);
        setLoading(false);
    };

    const PaymentData = async () => {
        let data = await getDataWithToken(getPaymentList + `?user_id=${propsValue}`);
        setPaymentList(data.data.map((e, index) => {
            return {
                Si: index + 1,
                InvoiceNo: <span>{e.invoice_number}</span>,
                IndustryName: <span>{e.industry_name}</span>,
                PaidAt: e.payment_date,
                PaymentMethod: e.payment_method_name,
                Amount: e.paid_amount,
                Status: e.payment_status,
                // action:
                //     <div>
                //         <span>
                //             <Btn attrBtn={{ style: style2, color: 'primary btn-xs', type: 'button', onClick: () => redirectToEditForm(e) }}>Edit </Btn>
                //         </span>
                //         <span>
                //             <Btn attrBtn={{ style: style2, className: 'me-2', color: 'danger btn-xs', type: 'button', onClick: () => { deleteAccountList(e.id) } }}>Delete</Btn>
                //         </span>
                //     </div >
            }
        }))
    };
    return (
        <Col sm="12" xl="6 xl-100">
            <Card>
                <CardBody>
                    <Nav className="border-tab nav-primary" tabs>
                        <NavItem>
                            <NavLink className={PrimarycolorLineTab === '1' ? 'active' : ''} style={{ cursor: 'pointer' }} onClick={() => setPrimarycolorLineTab('1')}>{Invoice}</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={PrimarycolorLineTab === '2' ? 'active' : ''} style={{ cursor: 'pointer' }} onClick={() => setPrimarycolorLineTab('2')}>{Payment}</NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={PrimarycolorLineTab}>
                        <TabPane className="fade show" tabId="1">
                            <div className="order-history table-responsive">
                                <DataTable
                                    columns={InvoiceDataColumns}
                                    data={invoiceList}
                                    progressPending={loading}
                                    pagination
                                    paginationServer
                                    defaultSortAsc
                                    paginationTotalRows={totalRows}
                                    paginationDefaultPage={currentPage}
                                    onChangeRowsPerPage={handlePerRowsChange}
                                    onChangePage={handlePageChange}
                                    // selectableRows
                                    onSelectedRowsChange={({ selectedRows }) => console.log(selectedRows)}
                                />
                            </div>
                        </TabPane>
                        <TabPane tabId="2">
                            <div className="order-history table-responsive">
                                <DataTable
                                    data={paymentList}
                                    columns={PaymentColumns}
                                    pagination
                                    style={{ border: '1px solid' }}
                                />
                            </div>
                        </TabPane>
                    </TabContent>
                </CardBody>
            </Card>
        </Col>
    );
};

export default BottomTableTabs;