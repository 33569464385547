import { Home, Box, Users, ShoppingBag, Mail } from 'react-feather';

export const MENUITEMS = [
    {
        menutitle: 'General',
        Items: [
            // { path: `${process.env.PUBLIC_URL}/app/file-manager`, icon: GitPullRequest, title: 'file Manger', bookmark: true, type: 'link' },
            { path: `${process.env.PUBLIC_URL}/dashboard/ecommerce`, icon: Home, title: 'Dashboard', bookmark: true, type: 'link' },
            {
                title: 'Subscriptions', icon: Box, type: 'sub', active: false, children: [
                    { path: `${process.env.PUBLIC_URL}/subscriptions/active-sub`, title: 'Active Subscribers', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/subscriptions/temp-sub`, title: 'Temporary Subscribers', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/subscriptions/trial-expired`, title: 'Trial Expired', type: 'link' },
                ]
            },
            {
                title: 'Billing', icon: ShoppingBag, type: 'sub', active: false, children: [
                    { path: `${process.env.PUBLIC_URL}/billing/invoice-list`, title: 'Invoice', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/billing/payment-list`, title: 'Payment', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/billing/subscription`, title: 'Subscription', type: 'link' },
                ]
            },
            {
                title: 'Configuration', icon: Mail, type: 'sub', children: [
                    { path: `${process.env.PUBLIC_URL}/configuration/industry-list`, title: 'Industries', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/configuration/tax-list`, type: 'link', title: 'Tax Class' },
                    { path: `${process.env.PUBLIC_URL}/configuration/account`, title: 'Account', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/configuration/packages`, title: 'Packages', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/configuration/coupon`, title: 'Coupon', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/configuration/payment-method`, title: 'Payment Method', type: 'link' },
                ]
            },

            // {
            //     title: 'Report', path: `${process.env.PUBLIC_URL}/app/chat-app`, bookmark: true, icon: MessageCircle, type: 'sub', children: [
            //         { path: `${process.env.PUBLIC_URL}/app/chat-app`, type: 'link', title: 'Chat-app' },
            //         { path: `${process.env.PUBLIC_URL}/app/chat-video-app`, type: 'link', title: 'Chat-video-app' },
            //         { path: `${process.env.PUBLIC_URL}/app/project/project-list`, type: 'link', title: 'Project List' },
            //         { path: `${process.env.PUBLIC_URL}/app/project/new-project`, type: 'link', title: 'Create New' }
            //     ]
            // },
            {
                title: 'Master Records', icon: Users, path: `${process.env.PUBLIC_URL}/app/users/userProfile`, type: 'sub', bookmark: true, active: false, children: [
                    { path: `${process.env.PUBLIC_URL}/master-records/role`, title: 'Role', type: 'link' },
                    // { path: `${process.env.PUBLIC_URL}/master-records/role-add`, title: 'Add Role', type: 'link' },
                    // { path: `${process.env.PUBLIC_URL}/master-records/addons`, title: 'Addons', type: 'link' },
                    // { path: `${process.env.PUBLIC_URL}/master-records/addons-add`, title: 'Add Addons', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/master-records/user-address`, title: 'Users Address', type: 'link' },
                    // { path: `${process.env.PUBLIC_URL}/master-records/user-address-add`, title: 'Add User Address', type: 'link' },
                ]
            },
        ]
    },
];