import React, { Fragment } from 'react';
import { Card, CardHeader, CardBody, Row, Modal, ModalHeader, ModalBody, Label, FormGroup, Form, Media, Input } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { useState, useEffect } from 'react';
import { deletePaymentMethod, showPaymentMethod, getPackages, getStatus } from '../../../CommonURL';
import { Link, useNavigate } from 'react-router-dom';
import SweetAlert from 'sweetalert2';
import { Add } from '../../../Constant';
import { toast } from 'react-toastify';
import { Btn, H5, H3, Breadcrumbs } from '../../../AbstractElements';
import { getDataWithToken, postDataWithToken } from '../../../Utils/restUtils';
import SearchList from '../../../CommonElements/Search';
import { paymentColumns } from '../../../Data/Ecommerce/PaymentsTableList';
import Transparentspinner from '../../Common/loader/Transparentspinner';

const PaymentMethodList = () => {
    const [PackagesData, setPackagesData] = useState([]);
    const [packagesList, setPackagesList] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)

    const style2 = { width: 60, fontSize: 13, padding: 3 };
    useEffect(() => {
        getPackagesData(); 
    }, [searchKeyword]);

    const filteredItems = packagesList.filter(
        (item) => item.name && item.name.toLowerCase().includes(searchKeyword.toLowerCase()) ||
            item.description && item.description.toLowerCase().includes(searchKeyword.toLowerCase()) ||
            item.tax && item.tax.toLowerCase().includes(searchKeyword.toLowerCase()) ||
            item.industry_name && item.industry_name.toLowerCase().includes(searchKeyword.toLowerCase()) ||
            item.features && item.features.toLowerCase().includes(searchKeyword.toLowerCase())
    );

    const updateSearchKeyword = (keyword) => {
        setSearchKeyword(keyword);
    };

    const handleSwitchToggle = async (event, itemId) => {
        const checked = event.target.checked;
        let reqSwitch = null;
        if (checked === true) {
            reqSwitch = { coupon_id: itemId, status: 'inactive' }
        } else {
            reqSwitch = { coupon_id: itemId, status: 'active' }
        }
        const statusChange = await postDataWithToken(getStatus, reqSwitch);
        if (statusChange.data.status === true) {
            toast.success(statusChange.data.message);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
    }

    const deletePackagesList = (id) => {
        SweetAlert.fire({
            title: 'Are you sure?',
            text: 'Once deleted, you will not be able to recover this imaginary file!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'cancel',
            reverseButtons: true
        }).then(async (result) => {
            setLoading(true);
            if (result.value) {
                const deleteRes = { payment_method_id: id }
                const packageRemove = await postDataWithToken(deletePaymentMethod, deleteRes);
                if (packageRemove.data.status === true) {
                    SweetAlert.fire(
                        'Deleted!',
                        'Your file has been deleted.',
                        'success'
                    );
                    getPackagesData();
                } else {
                    navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
                }
            } else {

            }
            setLoading(false);
        });
    };

    const redirectToEditForm = (data) => {
        const editFormURL = `${process.env.PUBLIC_URL}/configuration/payment-method-edit?id=${data.id}`;
        navigate(editFormURL);
    };

    const getPackagesData = async () => {
        setLoading(true);
        let data = await getDataWithToken(showPaymentMethod);
        setResetPaginationToggle(!resetPaginationToggle);
        setPackagesList(data.data)
        if (searchKeyword === '') {
            setPackagesData(data.data.map((e, index) => {
                return {
                    Si: index + 1,
                    Name: e.name,
                    Type: e.type,
                    account_name: e.account_name,
                    Status: <Media body className={`flex-grow-1 text-end icon-state`}>
                        <Label className="switch">
                            <Input type="checkbox"
                                defaultChecked={e.status === "active" ? true : false}
                                onChange={(event) => handleSwitchToggle(event, e.id)}
                            />
                            <span className="switch-state" ></span>
                        </Label>
                    </Media>,
                    action:
                        <div>
                            <span>
                                <Btn attrBtn={{ style: style2, className: 'me-2', color: 'primary btn-xs', type: 'button', onClick: () => redirectToEditForm(e) }}>Edit </Btn>
                            </span>
                            <span>
                                <Btn attrBtn={{ style: style2, color: 'danger btn-xs', type: 'button', onClick: () => { deletePackagesList(e.id) } }}>Delete</Btn>
                            </span>
                        </div >
                }
            }))
        }
        setLoading(false);
    };
    return (
        <Fragment>
            {loading && <Transparentspinner />}
            <Breadcrumbs mainTitle='Payment Method' parent="Configuration" title="Payment" />
            <Card>
                <CardHeader><H3>Payment  method List</H3></CardHeader>
                <div style={{ paddingLeft: '22px', marginRight: '22px', marginBottom: '-10px' }}>
                    <div style={{ float: 'left' }}>
                        <SearchList updateSearchKeyword={updateSearchKeyword} />
                    </div>
                    <div style={{ float: 'right' }}>
                        <Link to={`${process.env.PUBLIC_URL}/configuration/payment-method-add`} className="btn btn-primary">{Add}</Link>
                    </div>
                    <div style={{ clear: 'both' }}></div>
                </div>
                <CardBody>
                    <div className="order-history table-responsive">
                        <DataTable
                            pagination
                            columns={paymentColumns}
                            data={PackagesData}
                        />
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    );
};

export default PaymentMethodList;