import React, { Fragment, useState } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row } from 'reactstrap';
import { CityError, AddIndustry, Comment, Name, NameError, IconError, SubmitForm, EditIndustry } from '../../../Constant';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Breadcrumbs, Btn, H3 } from "../../../AbstractElements";
import { getData, postDataWithToken } from "../../../Utils/restUtils";
import { getIndustry, uploadIndustry } from "../../../CommonURL";
import SpinnerLoader from "../../../Layout/SpinnerLoader";
import { useLocation } from 'react-router-dom';
import { useEffect } from "react";
import { toast } from 'react-toastify';

const IndustryEdit = () => {
    const location = useLocation();
    const searchParams = location.search;
    const params = new URLSearchParams(searchParams);
    const param1 = params.get("id");
    const { register, handleSubmit, control, formState: { errors } } = useForm({});
    const [propsValue, setPropsValue] = useState({});
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const formData = new FormData();

    useEffect(() => {
        getIndustryData();
    }, [])


    const getIndustryData = async () => {
        let data = await getData(getIndustry + `?industry_id=${param1}`);
        setPropsValue(data.data[0])
    }


    const onSubmit = async (data) => {
        setLoading(true);
        if (data.name !== propsValue.name) {
            formData.append('name', data.name);
        } else {
            formData.append('name', propsValue.name);
        }
        if (data.url !== propsValue.url) {
            formData.append('client_url', data.url);
        } else {
            formData.append('client_url', propsValue.client_url);
        }
        if (data.message !== propsValue.comment) {
            formData.append('comment', data.message);
        } else {
            formData.append('comment', propsValue.comment);
        }
        if (data.icon) {
            formData.append('icon', data.file[0]);
        } else {
            formData.append('icon', propsValue.icon);
        }
        formData.append(' industry_id',param1);
        const uploadIndustryResponse = await postDataWithToken(uploadIndustry, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        if (uploadIndustryResponse.data.message === "success") {
            toast.success('Success...!')
            navigate(`${process.env.PUBLIC_URL}/configuration/industry-list`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        setLoading(false);
    };
    return (
        <Fragment>
            {loading && (
                <SpinnerLoader />
            )}
            <Breadcrumbs mainTitle='Edit Industry' parent="Forms Controls" title="Industry Forms" />
            <Card>
                <CardHeader className="pb-0">
                    <H3>{EditIndustry}</H3>
                    {/* <span>{CustomstyleText} <code className="text-danger">{novalidate}</code> {CustomstyleText2} <code className="text-danger">&lt;{form}&gt;</code>{CustomstyleText3}</span><span>{CustomstyleText4} <code className="text-danger">{invalid} </code> {and} <code className="text-danger">{valid} </code> {CustomstyleText5}</span> */}
                </CardHeader>
                <CardBody>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Row className="g-3">
                            <Col md='6'>
                                <Label className="form-label" for="validationCustom01">{Name}</Label>
                                <input className="form-control" id="validationCustom01" type="text"
                                    placeholder="Username"
                                    {...register('name', { required: true })}
                                    value={propsValue && propsValue.name ? propsValue.name : ''}
                                    onChange={(e) => {
                                        setPropsValue((prevPropsValue) => ({
                                            ...prevPropsValue,
                                            name: e.target.value,
                                        }));
                                    }}
                                />
                                <span className="text-danger">{errors.firstname && NameError}</span>
                            </Col>
                            <Col md="6">
                                <Label className="form-label">Icon</Label>
                                <Controller
                                    name="file"
                                    control={control}
                                    render={({ field }) => (
                                        <div style={{ display: 'flex' }}>
                                            <input
                                                className="form-control"
                                                style={{ width: '80%' }}
                                                type="file"
                                                onChange={(e) => field.onChange(e.target.files)}
                                                accept='.png, .jpg, .jpeg'
                                            />
                                            <img src={propsValue.icons} style={{ height: '40px', paddingLeft: "30px" }} />
                                        </div>
                                    )}
                                />
                                <span className="text-danger">{errors.icon && IconError}</span>
                            </Col>
                        </Row>
                        <Row className="g-3">
                            <Col md='12'>
                                <Label className="form-label pt-3" for="validationCustom03">URL/Domain Name</Label>
                                <input className="form-control" id="validationCustom03" type="text"
                                    placeholder="https://www.example.com" defaultValue={'https://www.'}
                                    {...register('url', { required: true })}
                                    value={propsValue && propsValue.client_url ? propsValue.client_url : ''}
                                    onChange={(e) => {
                                        setPropsValue((prevPropsValue) => ({
                                            ...prevPropsValue,
                                            client_url: e.target.value,
                                        }));
                                    }}
                                />
                                <span className="text-danger">{errors.city && CityError}</span>
                            </Col>
                        </Row>
                        <Row className="g-3">
                            <Col>
                                <Label className="form-label pt-3">{Comment}</Label>
                                <Controller
                                    name="message"
                                    control={control}
                                    render={({ field }) => (
                                        <textarea
                                            className="form-control"
                                            {...field}
                                            rows="3" // Set the 'rows' attribute to 3 here
                                            value={field.value !== undefined ? field.value : (propsValue.comment || '')}
                                            onChange={(e) => {
                                                field.onChange(e.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Col>
                        </Row>
                        <FormGroup>
                        </FormGroup>
                        <Btn attrBtn={{ color: "primary" }} type="submit">{SubmitForm}</Btn>
                    </Form>
                </CardBody>
            </Card>
        </Fragment>
    )
}

export default IndustryEdit;