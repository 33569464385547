import React, { Fragment, useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import AboutMe from '../../../Bonus-Ui/Tour/Leftbar/AboutMe';
import UserProfile from '../../../Bonus-Ui/Tour/UserProfile';
import { useLocation } from 'react-router';
import { getIndustryUser } from '../../../../CommonURL';
import { getDataWithToken } from '../../../../Utils/restUtils';
import SubscriptionTableTabs from '../../../Bonus-Ui/Tour/SubscriptionTableTabs';
import BottomTableTabs from '../../../Bonus-Ui/Tour/BottomTableTabs';

const UsersProfileContain = () => {
    const location = useLocation();
    const searchParams = location.search;
    const params = new URLSearchParams(searchParams);
    const param1 = params.get("id");
    const param2 = params.get("order_id");
    const [personalDetails,setPersonalDetails]=useState({});

    useEffect(() => {
        industryUserData();
    }, [])

    const industryUserData = async () => {
        const response = await getDataWithToken(getIndustryUser + `?page=&page_length=&search=&industry_id=&user_id=${param1}`);
        setPersonalDetails(response.data.data[0]);
    }

    return (
        <Fragment>
            <Breadcrumbs mainTitle="User Profile" parent="Users" title="User Profile" />
            <Container fluid={true}>
                <div className="user-profile">
                    <Row>
                        <UserProfile details={personalDetails} param={param1}/>
                        <Col xl="3" md="5" className="xl-35 box-col-30">
                            <div className="default-according style-1 faq-accordion job-accordion">
                                <Row>
                                    <Col xl='12'>
                                        <AboutMe />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col xl="9" md="7" className="xl-65 box-col-70">
                            <Row>
                                <SubscriptionTableTabs params={param1}/>
                            </Row>
                        </Col>
                        <Col xl="12" md="12">
                            <Row>
                                <BottomTableTabs params={param1}/>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Container>
        </Fragment>
    );
};
export default UsersProfileContain;