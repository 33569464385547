import React, { Fragment, useEffect, useState } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row } from 'reactstrap';
import { Btn, H3, Breadcrumbs } from "../../../AbstractElements";
import { useForm, Controller } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { getTaxEdit, uploadTax } from "../../../CommonURL";
import { postDataWithToken } from "../../../Utils/restUtils";
import { DiscriptionText,  Name, NameError, LastNameError, SubmitForm, TaxPer, EditTax } from '../../../Constant';
import SpinnerLoader from "../../../Layout/SpinnerLoader";
import Transparentspinner from "../../Common/loader/Transparentspinner";

const TaxEdit = () => {
    const location = useLocation();
    const searchParams = location.search;
    const params = new URLSearchParams(searchParams);
    const param1 = params.get("id");
    const { register, handleSubmit, control, formState: { errors }, setValue } = useForm({});
    const [tax,setTax]=useState('');
    const [loading,setLoading]=useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        taxData(); // do not put await here
    }, []);

    const taxData = async () => {
        setLoading(true);
        const taxId = { tax_id: param1 };
        const data = await postDataWithToken(getTaxEdit, taxId);
        setTax(data.data.data.taxes);
        setValue('name', data.data.data.taxes.name);
        setValue('message', data.data.data.taxes.description);
        setValue('taxper', data.data.data.taxes.tax_percentage);
        setLoading(false);
    };

    const onSubmit = async (data) => {
        setLoading(true);
        const postRequest={tax_id:param1,Tax_group_id:tax.tax_group_id, name:data.name,tax_percentage:data.taxper, description:data.message }
        const addTaxResponse = await postDataWithToken(uploadTax, postRequest);
        if (addTaxResponse.data.message==='success') {
            // alert('You submitted the form and stuff!');
            navigate(`${process.env.PUBLIC_URL}/editor/tax-list`);
        } else {
            setLoading(false);
            // errors.showMessages();
        }
        setLoading(false);
    };
    return (
        <Fragment>
            {loading && (
                <Transparentspinner/>
            )}
            
            <Breadcrumbs mainTitle={'Edit Tax For '+tax.name} parent="Configuration" title="Tax" />
            <Card>
                <CardHeader className="pb-0">
                    <H3>{EditTax}</H3>
                </CardHeader>
                <CardBody>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Row className="g-3">
                            <Col md='6'>
                                <Label className="form-label" for="validationCustom01">{Name}</Label>
                                <input className="form-control" id="validationCustom01" type="text" placeholder="Username" {...register('name', { required: true })} />
                                <span className="text-danger">{errors.name && NameError}</span>
                            </Col>
                            <Col md="6">
                            <Label className="form-label" for="validationCustom02">{TaxPer}</Label>
                                <input className="form-control" id="validationCustom02" type="text" placeholder="Tax%" {...register('taxper',{ required: true })} />
                                <span className="text-danger">{errors.taxper && LastNameError}</span>   
                            </Col>
                        </Row>
                        <Row className="g-3">
                            <Col>
                                <Label className="form-label pt-3">{DiscriptionText}</Label>
                                <Controller
                                    name="message"
                                    control={control}
                                    render={({ field }) => (
                                        <textarea
                                            className="form-control"
                                            {...field}
                                            rows="3" // Set the 'rows' attribute to 3 here
                                        />
                                    )}
                                />
                            </Col>
                        </Row>
                        <FormGroup>
                        </FormGroup>
                        <Btn attrBtn={{ color: "primary" }} type="submit">{SubmitForm}</Btn>
                    </Form>
                </CardBody>
            </Card>
        </Fragment>
    )
}
export default TaxEdit;