import React, { useState } from 'react';
import { ExtendDays, Save } from '../../../Constant';
import { Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { Btn } from '../../../AbstractElements';
import { getMakePayment, postExtendDays } from '../../../CommonURL';
import Transparentspinner from '../../Common/loader/Transparentspinner';
import { postDataWithToken } from '../../../Utils/restUtils';
import { toast } from 'react-toastify';

function PaymentFormPopUp({ orderId, setModal }) {
  const { register, handleSubmit, formState: { errors } } = useForm({});
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);
    const postRequest = {
      order_id: orderId.id,
    }
    const postDays = await postDataWithToken(getMakePayment, postRequest);
    if (postDays.data.status === true) {  
      toast.success(postDays.data.message)
      setModal(false);
    } else {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
    }
    setLoading(false);
  };
  return (
    <>
      {loading && (
        <Transparentspinner />
      )}
      <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
        <Row className="g-3">
          <Col md='6'>
            <Label className="form-label" for="extendDays">{ExtendDays}</Label>
            <input className="form-control" id="extendDays" type="number" min={0} {...register('extendDays', { required: true })} />
          </Col>
        </Row>
        <FormGroup>
        </FormGroup>
        <Btn attrBtn={{ color: "primary" }} type="submit">{Save}</Btn>
      </Form>
    </>
  )
}

export default PaymentFormPopUp