import React, { Fragment, useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { deleteData, getData, postDataWithToken } from '../../../Utils/restUtils';
import { Card, CardBody, CardHeader, Col, Container, Input, Label, Media, Row } from 'reactstrap';
import { Add } from '../../../Constant';
import { Link, useNavigate } from 'react-router-dom';
import SweetAlert from 'sweetalert2';
import { Btn, H3, H4, Image, Breadcrumbs } from '../../../AbstractElements';
import { deleteIndustry, getIndustry, statusChangeIndustry } from '../../../CommonURL';
import SearchList from '../../../CommonElements/Search';
import { toast } from 'react-toastify';
import { industryColumns } from '../../../Data/Ecommerce/IndustriesList';

const IndustryTable = () => {
    const [industryData, setIndustryData] = useState([]);
    const [industriesData, setIndustriesData] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const navigate = useNavigate();
    const style2 = { width: 60, fontSize: 13, padding: 3 };
    useEffect(() => {
        getIndustryData(); // do not put await here
    }, [searchKeyword]);

    const filteredItems = industriesData.filter(
        (item) => item.name && item.name.toLowerCase().includes(searchKeyword.toLowerCase()) ||
            item.comment && item.comment.toLowerCase().includes(searchKeyword.toLowerCase())
    );

    const updateSearchKeyword = (keyword) => {
        setSearchKeyword(keyword);
    };

    const deleteIndustryList = (userId) => {
        SweetAlert.fire({
            title: 'Are you sure?',
            text: 'Once deleted, you will not be able to recover this imaginary file!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'cancel',
            reverseButtons: true
        }).then(async (result) => {
            if (result.value) {
                const deleteRes = { industry_id: userId }
                const industryDelete = await postDataWithToken(deleteIndustry, deleteRes);
                if (industryDelete.data.message === 'success') {
                    SweetAlert.fire(
                        'Deleted!',
                        'Your file has been deleted.',
                        'success'
                    );
                    getIndustryData();
                } else {
                    navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
                }
            } else {

            }
        });
    };

    const redirectToEditForm = (data) => {
        const editFormURL = `${process.env.PUBLIC_URL}/configuration/industry-edit?id=${data.id}`;
        navigate(editFormURL);
    };

    const handleSwitchToggle = async (event, itemId) => {
        const checked = event.target.checked;
        let reqSwitch = null;
        if (checked === true) {
            reqSwitch = { industry_id: itemId, status: 'inactive' }
        } else {
            reqSwitch = { industry_id: itemId, status: 'active' }
        }
        const statusChange = await postDataWithToken(statusChangeIndustry, reqSwitch);
        if (statusChange.data.status === true) {
            toast.success(statusChange.data.message);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
    }

    const getIndustryData = async () => {
        let data = await getData(getIndustry);
        setResetPaginationToggle(!resetPaginationToggle);
        setIndustriesData(data.data)
        if (searchKeyword === '') {
            setIndustryData(data.data.map((e) => {
                return {
                    icons: <Image attrImage={{ src: e.icons, alt: '' }} />,
                    name: <div>
                        <H4>{e.name}</H4>
                    </div>,
                    url: e.client_url,
                    comment: <div>
                        <span>{e.comment}</span>
                    </div>,
                    status: <div>{
                        <Media body className={`flex-grow-1 text-end icon-state`}>
                            <Label className="switch">
                                <Input type="checkbox"
                                    defaultChecked={e.status === "active" ? true : false}
                                    onChange={(event) => handleSwitchToggle(event, e.id)}
                                />
                                <span className="switch-state" ></span>
                            </Label>
                        </Media>
                    }</div>,
                    action:
                        <div>
                            <span>
                                <Btn attrBtn={{ style: style2, className: 'me-2', color: 'primary btn-xs', type: 'button', onClick: () => redirectToEditForm(e) }}>Edit </Btn>
                            </span>
                            <span>
                                <Btn attrBtn={{ style: style2, color: 'danger btn-xs', type: 'button', onClick: () => { deleteIndustryList(e.id) } }} >Delete</Btn>
                            </span>
                        </div >
                }
            }))
        } else {
            setIndustryData(filteredItems.map((e) => {
                return {
                    icons: <Image attrImage={{ src: e.icons, alt: '' }} />,
                    name: <div>
                        <H4>{e.name}</H4>
                        {/* <span>Vida Loca - Blue Denim Fit Men's  Jacket.</span> */}
                    </div>,
                    url: e.client_url,
                    comment: <div>
                        <span>{e.comment}</span>
                    </div>,
                    status: <Media body className={`flex-grow-1 text-end icon-state`}>
                        <Label className="switch">
                            <Input type="checkbox"
                                defaultChecked={e.status === "active" ? true : false}
                                onChange={(event) => handleSwitchToggle(event, e.id)}
                            />
                            <span className="switch-state" ></span>
                        </Label>
                    </Media>,
                    action:
                        <div>
                            <span>
                                <Btn attrBtn={{ style: style2, className: 'me-2', color: 'primary btn-xs', type: 'button', onClick: () => redirectToEditForm(e) }}>Edit </Btn>
                            </span>
                            <span>
                                <Btn attrBtn={{ style: style2, color: 'danger btn-xs', type: 'button', onClick: () => { deleteIndustryList(e.id) } }}>Delete</Btn>
                            </span>
                        </div >
                }
            }))
        }
    };
    return (
        <Fragment>
            
            <Breadcrumbs title="Industries" mainTitle="Industries" parent="Configuration" />
            <Container fluid={true} className='list-products'>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader><H3>Industry List</H3></CardHeader>
                            <div style={{ paddingLeft: '22px', marginRight: '22px', marginBottom: '-40px' }}>
                                <div style={{ float: 'left' }}>
                                    <SearchList updateSearchKeyword={updateSearchKeyword} />
                                </div>
                                <div style={{ float: 'right' }}>
                                    <Link to={`${process.env.PUBLIC_URL}/configuration/industry-add`} className="btn btn-primary">{Add}</Link>
                                </div>
                                <div style={{ clear: 'both' }}></div>
                            </div>
                            <CardBody>
                                <div className="table-responsive product-table">
                                    <div id='basic-1_wrapper' className='dataTables_wrapper no-footer'>
                                        <DataTable
                                            className='display dataTable no-footer'
                                            pagination
                                            paginationResetDefaultPage={resetPaginationToggle}
                                            subHeader
                                            persistTableHead
                                            columns={industryColumns}
                                            data={industryData}
                                        />
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};
export default IndustryTable;