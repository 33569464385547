import React, { Fragment, useState, useEffect } from 'react';
import { Col, Card, CardHeader, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { H3, Btn, Breadcrumbs, H4 } from '../../../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';
import SweetAlert from 'sweetalert2';
import { getIndustryUser, getOtp, OperatorUrl } from '../../../CommonURL';
import { getDataWithToken, postDataWithoutToken, postDataWithToken } from '../../../Utils/restUtils';
import { Add, Delete, Edit, LoginAs } from '../../../Constant';
import SearchList from '../../../CommonElements/Search';
import Transparentspinner from '../../Common/loader/Transparentspinner';

const CompanyTable = () => {
    const navigate = useNavigate();
    const [companyList, setCompanyList] = useState([]);
    const [companyData, setCompanyData] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const toggleStyle = { padding: '10px 20px', opacity: '0.8', fontSize: '14px' }

    const CompanyColumns = [
        {
            name: <H4>Sl</H4>,
            selector: row => row.Si,
            sortable: true,
            width: '70px'
        },
        {
            name: <i className="fa fa-gears" style={{ fontSize: '15px' }}></i>,
            selector: row => row.action,
            cell: row => <ActionCell row={row} />,
            center: true,
            width: '55px',

        },
        {
            name: <H4>Image</H4>,
            selector: row => row.Image,
            sortable: true,
            width: '110px',
            wrap: true
        },
        {
            name: <H4>Name</H4>,
            selector: row => <Link to={`${process.env.PUBLIC_URL}/companies/company-list/userProfile?id=${row.id}`}>{row.Name}</Link>,
            sortable: true,
            width: '110px',
            wrap: true,

        },
        {
            name: <H4>Email</H4>,
            selector: row => row.Email,
            sortable: true,
            width: '190px',
            wrap: true,
        },
        {
            name: <H4>Phone No.</H4>,
            selector: row => "+" + row.Mobile,
            sortable: true,
            width: '150px',
            wrap: true,

        },
        {
            name: <H4>Industry</H4>,
            selector: row => row.Industry,
            sortable: true,
            width: '100px',
            wrap: true,

        },
        {
            name: <H4>State</H4>,
            selector: row => row.State,
            sortable: true,
            width: '110px',
            wrap: true,
        },
        {
            name: <H4>Country</H4>,
            selector: row => row.Country,
            sortable: true,
            width: '110px',
            wrap: true,
        },
        {
            name: <H4>Status</H4>,
            selector: row => row.Status,
            cell: row => (
                <span
                    className={`badge badge-${row.Status}`}
                >
                    {row.Status}
                </span>
            ),
            sortable: true,
            width: '80px',
            wrap: true,
        },
    ];

    useEffect(() => {
        industryUserData(1); // do not put await here
    }, [searchKeyword]);

    const handlePageChange = page => {
        industryUserData(page);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        industryUserData(page, newPerPage);
        setPerPage(newPerPage);
    };

    const redirectToEditForm = (data) => {
        const editFormURL = `${process.env.PUBLIC_URL}/companies/company-edit?id=${data.id}`;
        navigate(editFormURL);
    };

    const redirectToView = (data) => {
        const editFormURL = `${process.env.PUBLIC_URL}/companies/company-list/userProfile?id=${data.id}`;
        navigate(editFormURL);
    };

    const ActionCell = ({ row }) => {
        const [dropdownOpen, setDropdownOpen] = React.useState(false);
        const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
        const redirectToLogin = async (row) => {
            const userId = { user_id: row.id }
            const token = await postDataWithoutToken(getOtp, userId);
            if (token.data.status === true) {
                const url = OperatorUrl + `?user_id=${row.id}&token=${token.data.data}`;
                window.open(url, '_blank');
            }
        };

        return (
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle color="#00000" style={{ fontSize: '14px', zIndex: 1000 }}>
                    <i className="fa fa-ellipsis-v"></i>
                </DropdownToggle>
                <DropdownMenu container="body">
                    {/* <DropdownItem style={toggleStyle} onClick={() => redirectToEditForm(row)}>{Edit}</DropdownItem> */}
                    <DropdownItem style={toggleStyle} onClick={() => redirectToLogin(row)}>{LoginAs}</DropdownItem>
                    <DropdownItem style={{ padding: '10px 20px', color: 'red', opacity: '0.8', fontSize: '14px', margin: '0' }} onClick={() => deleteCompanyList()}>{Delete}</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        );
    };

    const deleteCompanyList = (userId) => {
        SweetAlert.fire({
            title: 'Are you sure?',
            text: 'Once deleted, you will not be able to recover this imaginary file!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'cancel',
            reverseButtons: true
        }).then(async (result) => {
            setLoading(true);
            if (result.value) {
                const deleteRes = { package_id: userId }
                const packageRemove = await postDataWithToken(deletePackage, deleteRes);
                if (packageRemove.data.message === 'success') {
                    SweetAlert.fire(
                        'Deleted!',
                        'Your file has been deleted.',
                        'success'
                    );
                    getPackagesData();
                } else {
                    navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
                    setLoading(false);
                }
            } else {
                navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
            }
            setLoading(false);
        });
    };

    const filteredItems = companyData.filter(
        (item) => item.first_name && item.first_name.toLowerCase().includes(searchKeyword.toLowerCase()) ||
            item.last_name && item.last_name.toLowerCase().includes(searchKeyword.toLowerCase()) ||
            item.industry_name && item.industry_name.toLowerCase().includes(searchKeyword.toLowerCase())
    );

    const updateSearchKeyword = (keyword) => {
        setSearchKeyword(keyword);
    };

    const industryUserData = async (page, size = perPage) => {
        setLoading(true);
        const response = await getDataWithToken(getIndustryUser + `?page=${page}&page_length=${size}&search=&industry_id=&user_id=`);
        setResetPaginationToggle(!resetPaginationToggle);
        setCompanyData(response.data.data)
        const SerialNo = (page - 1) * size + 1;
        if (searchKeyword === '') {
            setCompanyList(response.data.data.map((e, index) => {
                return {
                    Si: SerialNo + index,
                    Image: e.image ? (<img style={{ height: '40px', width: 'auto' }} src={e.profile_photo} alt="Company Logo" />) : <div></div>,
                    Name: <div>{e.first_name} {e.last_name}</div>,
                    Email: e.email,
                    Mobile: e.mobile,
                    Industry: e.industry_name,
                    State: e.state,
                    Country: e.country,
                    Status: e.status,
                    id: e.id,
                }
            }))
            setLoading(false);
        } else {
            setCompanyList(filteredItems.map((e, index) => {
                return {
                    Si: index + 1,
                    Image: <span>{e.image}</span>,
                    Name: <div>{e.first_name} {e.last_name}</div>,
                    Email: e.email,
                    Mobile: e.mobile,
                    Industry: e.industry_name,
                    State: e.state,
                    Country: e.country,
                    Status: e.status,
                    id: e.id,
                }
            }))
        }
        setTotalRows(response.data.total);
        setLoading(false);
    };

    return (
        <Fragment>
            {loading && <Transparentspinner />}
            <Breadcrumbs mainTitle='Subscriptions' parent="Subscriptions" title="Subscriptions" />
            <Card>
                <CardHeader>
                    <H3>Active Subscribers List</H3>
                </CardHeader>
                <div style={{ paddingLeft: '22px', marginRight: '22px', marginBottom: '-10px' }}>
                    <div style={{ float: 'left' }}>
                        <SearchList updateSearchKeyword={updateSearchKeyword} />
                    </div>
                    <div style={{ float: 'right' }}>
                        <Link to={`${process.env.PUBLIC_URL}/companies/company-add`} className="btn btn-primary">{Add}</Link>                    </div>
                    <div style={{ clear: 'both' }}></div>
                </div>
                <CardBody>
                    <div className="order-history table-responsive">
                        <DataTable
                            columns={CompanyColumns}
                            data={companyList}
                            pagination
                            paginationServer
                            defaultSortAsc
                            paginationTotalRows={totalRows}
                            paginationDefaultPage={currentPage}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            // selectableRows
                            onSelectedRowsChange={({ selectedRows }) => console.log(selectedRows)}
                        />
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    );
};

export default CompanyTable;